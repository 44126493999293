import React, { useEffect, useState } from 'react'
import TableComp from '../Table/Table'
import classes from '../Button/button.module.css'
import billingClass from './billing.module.css';

const Invoice = () => {

  const [openAddEmployeeDrawer, setOpenAddEmployeeDrawer] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  function handleResize() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={billingClass.billingContainer}>
      <p className={billingClass.title}>Invoicing</p>
      {/* <TableComp type="invoice" openAddEmployeeDrawer={openAddEmployeeDrawer} setOpenAddEmployeeDrawer={setOpenAddEmployeeDrawer} /> */}
    </div>
  )
}

export default Invoice;
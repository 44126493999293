import { Box, Stack, Text, Title, Flex, Group, Image, Button } from "@mantine/core"
import { IconEdit } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { EMPLOYEE_STATUS_BLOCK } from "../../../../choices/employee.choice";
import { SUBCONTRACTOR_TABLE_HEADER } from "../../../../choices/subcontractor.choices";
import TableComp from "./Components/Table";



const SubContractor = () => {

    const organizations = useSelector(state => state.organizations.organizationList)

    const serializeSubContractorData = (subcontractor = []) => {
        return subcontractor.map((subcontractor) => {
            return {
                ...subcontractor,
                status: EMPLOYEE_STATUS_BLOCK[subcontractor?.status],
                checked: false,
                actions:
                    <>
                        <Group onClick={() => { }} gap={8} justify='center' align='center' c={'#868E96'}>
                            <IconEdit style={{ width: '16px', height: '16px' }} />
                            <p >Edit</p>
                        </Group>
                    </>

            }
        })
    }

    const organizationList = organizations.map((organization) => {
        return (
            <Stack key={organization?.id} gap={12} mb={24}>
                <Group grow justify="space-between" align="center" wrap='nowrap'>
                    <Group grow gap={11.09}>
                        <Image src={organization?.logo} w={36.98} h={36.98} fit='contain' />
                        <Title order={3}>{organization?.name}</Title>
                    </Group>
                    <Button
                        maw={67}
                        h={32}
                        color="teal.8"
                        radius={6}
                        px={16}
                        fz={12}
                    >
                        Invite
                    </Button>
                </Group>
                <TableComp
                    tableHeaders={SUBCONTRACTOR_TABLE_HEADER}
                    data={serializeSubContractorData(organization?.subcontractor ?? [])}
                />
                {
                    organization?.subcontractor && organization?.subcontractor.length === 0 ?
                        <Stack gap={1} ta={'center'}>
                            <Title order={4}> No Sub-Contractor Added </Title>
                            <Text c="dimmed" fz={14}>Press the invite button to add a sub-contractor</Text>
                        </Stack>
                        :
                        <></>
                }
            </Stack>
        )
    })


    return (
        <Box>
            <Flex
                direction="column"
                justify="flex-start"
                gap={24}
                mx={24}
                my={24}
            >
                <Stack gap={12}>
                    <Title order={3}>Manage and invite Sub-Contractors</Title>
                    <Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                </Stack>
                <Stack>
                    {organizationList}
                </Stack>
            </Flex>
        </Box>
    )
}

export default SubContractor
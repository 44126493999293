import { Box, Text, Image, Card, BackgroundImage, Stack, Title, TextInput, PasswordInput, Group, Divider } from "@mantine/core"
import loginBackground from "../../assets/images/login-background.svg"
import logo from "../../assets/icons/rfxn-logo.svg";
import logo2 from "../../assets/icons/safety-vision.svg";
import { useForm } from "@mantine/form";
import { yupResolver } from "@mantine/form";
import * as yup from 'yup';
import { loginUser, googleLogin, resetPassword } from "../../middlewares/auth/auth";
import { useDispatch, useSelector } from "react-redux";

import { GoogleButton, SocialButton } from "../../components/Button/Button"
import { notifications } from "@mantine/notifications";
import idMeIcon from '../../assets/icons/idmelogo.svg'
import facebookIcon from '../../assets/icons/fb.svg'

import { useGoogleLogin } from '@react-oauth/google';
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useViewportSize } from '@mantine/hooks';
import PrimaryButton from "../../components/BaseComponents/Buttons/Button";
const Login = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loginButtonLoading, setLoginButtonLoading] = useState()
    const [forgotPassword, setForgotPassword] = useState(false)
    const [sendVerificationCode, setSendVerificationCode] = useState(false)
    const { width } = useViewportSize()
    const user = useSelector(state => state.login?.user)

    const loginValidation = yup.object().shape({
        email: yup.string().required("Email is required").email("Invalid email"),
        // password: yup.string().required("Password is required")
    })
    const loginForm = useForm({
        initialValues: {
            email: '',
            // password: ''
        },
        validate: yupResolver(loginValidation)
    })

    const inputStyles = {
        label: {
            fontSize: width > 786 ? '16px' : '14px',
            marginBottom: "10px",
            lineHeight: "18px",
            fontWeight: "600",
            color: "#393B42"
        },
        input: {
            height: "40px",
            fontSize: width > 786 ? '14px' : '12px',
            padding: "12px",
            lineHeight: "16px",
            borderRadius: "6px",
            backgroundColor: '#F1F3F5'
        },
        error: {
            fontSize: width > 786 ? '14px' : '10px',
            marginTop: "5px",
            lineHeight: "16px",
        },
        required: {
            color: '#3354F4'
        }
    }

    const login = async (e) => {
        const { hasErrors } = loginForm.validate()
        if (hasErrors)
            return
        else {
            setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
            const res = await dispatch(loginUser(loginForm.values))
            if (loginUser.fulfilled.match(res)) {
                setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
                setSendVerificationCode(true)
                res.payload.data.user?.role ? navigate(`/${res.payload.data.user?.role}/dashboard`) : navigate('/')
            } else if (loginUser.rejected.match(res)) {
                setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
                setSendVerificationCode(false)
                let message = 'Login Unsuccessfull'
                const errorResponse = res?.payload?.response?.data
                if (errorResponse && errorResponse?.error?.details?.non_field_errors)
                    message = errorResponse?.error?.details?.non_field_errors[0]
                window.toast(message, 'error')


            }
        }
    }

    const loginViaGoogle = useGoogleLogin({
        onSuccess: async (googleRes) => {
            const res = await dispatch(googleLogin({ access_token: googleRes.access_token }))
            if (googleLogin.fulfilled.match(res)) {

            }
        },
        onError: (error) => notifications.show({ message: error, autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, })
    });

    const resetUserPassword = async () => {
        if (loginForm.validate().errors.email) {
            loginForm.validate()
        }
        else {
            setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
            const res = await dispatch(resetPassword({ email: loginForm.values.email }))
            if (resetPassword.fulfilled.match(res)) {
                setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
                notifications.show({ message: 'Mail sent successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            } else if (resetPassword.rejected.match(res)) {
                setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
                notifications.show({ message: 'Could not sent the email', autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            }
        }
    }

    return (
        <Box bg={"#212529"} h={'100vh'} w={'100vw'}>
            <BackgroundImage src={loginBackground} h={'100vh'} w={'100vw'} fit="contain" px={width > 786 ? 50 : 30} pt={50} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <Stack gap={12} pos={'relative'} h={'100%'} justify="center">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {/* <Image src={logo} w={200} h={51.83} /> */}
                        <div>
                            <Text style={{ color: "#5C5F66" }}>Powered By</Text>
                            <img src={logo} width="100%" alt='' style={{ marginBottom: '65.58px' }} />
                        </div>
                        <div>
                            <Image src={logo2} w="100%" style={{ objectFit: "contain" }} />
                        </div>
                    </div>
                    <form onSubmit={(e) => {
                        e.preventDefault(); // Prevent default form submission
                        forgotPassword ? resetUserPassword(e) : login(e);
                    }}
                        style={{ marginTop: width > 786 ? '0px' : 'auto' }}
                    >
                        <Stack>
                            <Card maw={488} mx="auto" bg={'#F1F3F5'} px={width > 786 ? 40 : 30} py={width > 786 ? 40 : 30} radius={12} mb={20}>
                                <Stack gap={30}>
                                    <Stack gap={8} key="card_header">
                                        <Title order={width > 786 ? 3 : 4} >
                                            {forgotPassword ?
                                                'Reset password'
                                                :
                                                'Log in to your business account!'
                                            }
                                        </Title>
                                        <Text fz={width > 786 ? 16 : 12} c={'#5C5F66'}>
                                            {forgotPassword ?
                                                'Securing Your Account with a New Password'
                                                :
                                                'Protecting your information starts here'
                                            }
                                        </Text>
                                    </Stack>
                                    <Stack key="card_body" gap={width > 786 ? 20 : 10}>
                                        <TextInput
                                            label="Email"
                                            placeholder="Please enter your email"
                                            {...loginForm.getInputProps('email')}
                                            styles={inputStyles}
                                            required={true}
                                            size="sm"
                                        />
                                        {sendVerificationCode && (
                                            <>
                                                <TextInput
                                                    label="Login Code"
                                                    placeholder="Paste login code"
                                                    {...loginForm.getInputProps('loginCode')}
                                                    styles={inputStyles}
                                                    required={true}
                                                    size="sm"
                                                />
                                                <Text fz={width > 786 ? 16 : 12} c={'#5C5F66'}>
                                                    We sent a code to your inbox * <b>Resend in 24s</b>
                                                </Text>
                                            </>
                                        )}
                                        {/* {forgotPassword ?
                                            <></>
                                            :
                                            <Stack pos={'relative'}>
                                                <PasswordInput
                                                    label={"Password"}
                                                    placeholder="Please enter your password"
                                                    {...loginForm.getInputProps('password')}
                                                    styles={inputStyles}
                                                    required={true}
                                                    size="sm"
                                                />
                                                <a
                                                    style={{ textDecoration: 'underline', position: 'absolute', right: '0', top: '2px', color: '#5C5F66', fontSize: '12px', cursor: 'pointer' }}
                                                    onClick={() => setForgotPassword(forgotPassword => !forgotPassword)}
                                                >Forgot Password?</a>
                                            </Stack>
                                        } */}

                                    </Stack>

                                    {/* Old Code */}
                                    {/* {forgotPassword ?
                                        <Stack gap={width > 786 ? 20 : 10}>
                                            <PrimaryButton type='submit' size='md' loading={loginButtonLoading} h={48} text='Reset Password' />
                                            <Divider label="Or" labelPosition="center" color={'#ADB5BD'} />
                                            <PrimaryButton size='md' h={48} text='Back to login' onClick={() => setForgotPassword(forgotPassword => !forgotPassword)} />
                                        </Stack>
                                        :
                                        <Stack gap={width > 786 ? 20 : 10}>
                                            <PrimaryButton type='submit' size='md' loading={loginButtonLoading} h={48} text='Continue' />
                                            <Divider label="Or" labelPosition="center" color={'#ADB5BD'} />
                                            <Stack style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px' }}>
                                                <GoogleButton onClick={loginViaGoogle} radius={6} h={48} />
                                                <SocialButton leftSection={<img src={idMeIcon} alt="" />} color="#1D2E52" title="Continue with ID.me" />
                                                <SocialButton leftSection={<img src={facebookIcon} alt="" />} color="#0866FF" title="Log In with Facebook" />
                                            </Stack>
                                        </Stack>
                                    } */}

                                    {/* Updated Code */}
                                    <PrimaryButton type='submit' size='md' loading={loginButtonLoading} h={48} text='Continue' />

                                    {!sendVerificationCode && (
                                        <Stack gap={width > 786 ? 20 : 10}>
                                            <Divider label="Or" labelPosition="center" color={'#ADB5BD'} />
                                            <Stack style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px' }}>
                                                <GoogleButton onClick={loginViaGoogle} radius={6} h={48} />
                                                <SocialButton leftSection={<img src={idMeIcon} alt="" />} color="#1D2E52" title="Continue with ID.me" />
                                                <SocialButton leftSection={<img src={facebookIcon} alt="" />} color="#0866FF" title="Log In with Facebook" />
                                            </Stack>
                                        </Stack>
                                    )}

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Link to="/admin/signup" style={{ textDecoration: "none" }}>
                                            <Text c="black" fz={width > 786 ? 16 : 12}>
                                                Not registered? <span><b>Sign up</b></span>
                                            </Text>
                                        </Link>
                                        <Link to="/" style={{ textDecoration: "none" }}>
                                            <Text c="black" fz={width > 786 ? 16 : 12}> Login as a partner</Text>
                                        </Link>
                                    </div>
                                </Stack>
                            </Card>
                        </Stack>
                    </form>
                    <Group
                        w={'100%'}
                        justify={width > 786 ? 'center' : 'space-between'}
                        pb={50}
                        gap={0}
                        c={'#ADB5BD'}
                        style={{ marginTop: 'auto' }}
                    >
                        <Text style={{ cursor: "pointer" }} fz={width > 786 ? 16 : 12} >About us</Text>
                        <Text mx={38} style={{
                            background: 'linear-gradient(#2145F3, #459EEF)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}>U.S. VETERAN OWNED AND OPERATED</Text>
                        <Text style={{ cursor: "pointer" }} onClick={() => {
                            if (sendVerificationCode) {
                                setSendVerificationCode(false)
                            }
                        }} c="#ADB5BD" fz={width > 786 ? 16 : 12}>Back</Text>
                    </Group>
                </Stack>

            </BackgroundImage>
        </Box>
    )
}

export default Login
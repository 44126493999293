import { Stack, Text, Group } from "@mantine/core"
import './BaseSidebar.css'
import { NavLink } from "react-router-dom"
import { renderTabs } from "../../lib/routes"
import { useSelector } from "react-redux"

const BaseSidebar = () => {

    const user = useSelector((state) => state.login?.user)

    const navigationTile = renderTabs(user?.role).map(navigation => {
        return (
            <NavLink key={navigation.name} to={navigation.link} className={({ isActive }) =>
                isActive ? 'active' : 'navlink'
            }>
                <Group w={200} h={40} gap={8} py={12} pl={12} justify="flex-start" align="center" wrap="nowrap" >
                    {navigation.icon}
                    <Text span fz={14} fw={400} >{navigation.name}</Text>
                </Group>
            </NavLink>
        )
    })


    return (
        <>
            <Stack gap={4}>
                {navigationTile}
            </Stack>
        </>
    )
}
export default BaseSidebar
import { PasswordInput, TextInput } from "@mantine/core"
import { IconEye, IconEyeOff } from "@tabler/icons-react"
const InputBox = ({ rightIcon, leftIcon, value, label, placeHolder, isPassword, onChange, error, disabled }) => {


    // function inputType(){
    //     switch(inputType){
    //         case 'password':
    //             return (<PasswordInput
    //                 label={label}
    //                 placeholder={placeHolder}
    //                 visibilityToggleIcon={({ reveal }) =>
    //                     reveal ? (
    //                         <IconEyeOff style={{ width: '16px', height: '16px' }} />
    //                     ) : (
    //                         <IconEye style={{ width: '16px', height: '16px' }} />
    //                     )
    //                 }
    //                 radius="6px"
    //                 value={value}
    //                 onChange={(event) => onChange(event.target.value)}
    //                 error={error}
    //                 styles={() => ({
    //                     input: {
    //                         marginTop: '10px',
    //                     },
    //                     label: {
    //                         font: 'Roboto',
    //                         fontSize: '14px',
    //                         fontWeight: '600',
    //                         color: '#495057'

    //                     }
    //                 })
    //                 }
    //             />)
    //             break;
    //         case 'text': 
    //         return (<PasswordInput
    //             label={label}
    //             placeholder={placeHolder}
    //             visibilityToggleIcon={({ reveal }) =>
    //                 reveal ? (
    //                     <IconEyeOff style={{ width: '16px', height: '16px' }} />
    //                 ) : (
    //                     <IconEye style={{ width: '16px', height: '16px' }} />
    //                 )
    //             }
    //             radius="6px"
    //             value={value}
    //             onChange={(event) => onChange(event.target.value)}
    //             error={error}
    //             styles={() => ({
    //                 input: {
    //                     marginTop: '10px',
    //                 },
    //                 label: {
    //                     font: 'Roboto',
    //                     fontSize: '14px',
    //                     fontWeight: '600',
    //                     color: '#495057'

    //                 }
    //             })
    //             }
    //         />)   
    //         break;
    //     }
    // }

    return (
        <>
            {isPassword ?
                <PasswordInput
                    label={label}
                    placeholder={placeHolder}
                    visibilityToggleIcon={({ reveal }) =>
                        reveal ? (
                            <IconEyeOff style={{ width: '16px', height: '16px' }} />
                        ) : (
                            <IconEye style={{ width: '16px', height: '16px' }} />
                        )
                    }
                    radius="6px"
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    error={error}
                    styles={() => ({
                        input: {
                            marginTop: '10px',
                        },
                        label: {
                            font: 'Roboto',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#495057'

                        }
                    })
                    }
                />
                :
                <TextInput
                    label={label}
                    placeholder={placeHolder}
                    rightSection={rightIcon}
                    leftSection={leftIcon}
                    radius="6px"
                    onChange={(event) => onChange(event.target.value)}
                    value={value}
                    error={error}
                    disabled={disabled}
                    styles={() => ({
                        input: {
                            marginTop: '10px',
                            padding: "8px 12px",
                            borderRadius: "6px",
                        },
                        label: {
                            font: 'Roboto',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#495057'
                        }
                    })}
                />
            }
        </>

    )
}

export default InputBox
import {
    IconLayoutDashboard,
    IconUsers,
    IconAdjustments,
    IconBell,
    IconChartPie2,
    IconWallet,
    IconShieldHalf,
    IconSettings2
} from '@tabler/icons-react';

export const ADMIN_ROUTES = [
    {
        name: 'Dashboard',
        icon: <IconLayoutDashboard size={16} />,
        link: '/admin/dashboard',
        subMenus: [],
    },
    {
        name: 'Employee Management',
        icon: <IconUsers size={16} />,
        link: '/admin/employee-management',
        subMenus: [],
    },
    {
        name: 'Tier Management',
        icon: <IconAdjustments size={16} />,
        link: '/admin/tier-management',
        subMenus: [],
    },
    {
        name: 'Notifications',
        icon: <IconBell size={16} />,
        link: '/admin/notification',
        subMenus: [],
    },
    {
        name: 'Reporting and Analytics',
        icon: <IconChartPie2 size={16} />,
        link: '/admin/reporting-analytics',
        subMenus: [],
    },
    {
        name: 'Billing and Payments',
        icon: <IconWallet size={16} />,
        link: '/admin/billing',
        subMenus: [],
    },
    {
        name: 'Safety Awards',
        icon: <IconShieldHalf size={16} />,
        link: '/admin/safety-awards',
        subMenus: [],
    },
    {
        name: 'Settings',
        icon: <IconSettings2 size={16} />,
        link: '/admin/settings',
        subMenus: [],
    }
];

export const PARTNER_ROUTES = [
    {
        name: 'Dashboard',
        icon: <><IconLayoutDashboard size={16} /></>,
        link: '/partner/dashboard',
        subMenus: [],
    },
    {
        name: 'Company Management',
        icon: <><IconUsers size={16} /></>,
        link: '/partner/company-management',
        subMenus: [],
    },
    {
        name: 'Notifications',
        icon: <><IconBell size={16} /></>,
        link: '/partner/notifications',
        subMenus: [],
    },
    {
        name: 'Reporting and Analytics',
        icon: <><IconChartPie2 size={16} /></>,
        link: '/partner/reporting-and-analytics',
        subMenus: [],
    },
    {
        name: 'Settings',
        icon: <><IconSettings2 size={16} /></>,
        link: '/partner/settings',
        subMenus: [],
    }
];

export function renderTabs(role) {
    if (role) {
        if (role === "admin") {
            return ADMIN_ROUTES;
        } else if (role === "partner") {
            return PARTNER_ROUTES;
        } else {
            return null;
        }
    } else {
        return [];
    }
}
import { Container } from "@mantine/core";
import UserProfile from "../../components/Settings/UserProfile";
import CompanyProfile from "../../components/Settings/CompanyProfile";
import TabBaseLayout from "../../components/Layout/TabBaseLayout/TabBaseLayout";

const Settings = () => {
    const tabList = [
        { value: 'userProfile', label: 'User Profile' },
        { value: 'companyProfile', label: 'Company Profile' },
    ]
    return (
        <Container fluid style={{ height: '100%' }}>
            <TabBaseLayout tabList={tabList}>
                <UserProfile />
                <CompanyProfile />
            </TabBaseLayout>
        </Container>
    );
};

export default Settings
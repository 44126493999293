import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '@mantine/core/styles.css';

import "../src/assets/scss/_variables.scss";
import "../src/assets/scss/_mixins.scss";
import "../src/assets/scss/_utilities.scss";
import "../src/assets/scss/_base.scss";

import { createTheme, MantineProvider } from '@mantine/core';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './store/store';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Notifications, notifications } from '@mantine/notifications';

const root = ReactDOM.createRoot(document.getElementById('root'));
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
const width = window.innerWidth 

// Define your custom colors
const customColors = {
  electric: [
    '#E0E8FF',  // Lightest (Index 0)
    '#B3CFFF',  // Lighter (Index 1)
    '#80A8FF',  // Light (Index 2)
    '#4A8CFF',  // Moderate Light (Index 3)
    '#0078FF',  // Moderate (Index 4)
    '#0056C2',  // Dark Moderate (Index 5)
    '#3354F4',  // Normal (Primary Color at Index 6)
    '#003BB5',  // Dark (Index 7)
    '#002B8D',  // Darker (Index 8)
    '#001F6B',  // Darkest (Index 9)
  ],
};

const colors = {
  'electric': [...customColors.electric], // Add your custom colors
};

const theme = createTheme({
  fontFamily: 'Roboto, sans-serif', // Use a string for a single font-family or a comma-separated list
  colors: { ...colors },
  primaryColor: 'electric'
});

//creating Global Toaster function
window.toast = (message, type = 'success', duration = 3000) => {
  const toastColor = type === 'success' ? 'electric.6' : 'red';
  notifications.show({ message: message, autoClose: duration, color: toastColor, style: { width: width > 786 ? '400px' : '300px', position: 'fixed', bottom: '6rem', right: '2rem' }, });
}

root.render(
  <GoogleOAuthProvider clientId={googleClientId}>
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <MantineProvider theme={theme} >
          <Notifications position="bottom-right" />
          <App />
        </MantineProvider>
      </Provider>
    </PersistGate>
  </GoogleOAuthProvider>
);
import { IconX, IconCheck } from '@tabler/icons-react';
import { Loader, Notification, rem } from '@mantine/core';
import classes from './notification.module.css'

function NotificationComp() {
  // const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;
  return (
    <Notification
      className={classes.notificationContainer}
      withCloseButton={false}
      loading={<Loader color='#3354F4' />}  
      color="teal"
      title="Please wait until data is uploaded “employee.csv”"
      mt="md">
      Uploading data to the serve
    </Notification>
  )
}

export default NotificationComp;
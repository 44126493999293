import { Box, Card, Group, Stack, Title, Text, Pagination, Container } from "@mantine/core"
import { IconChevronDown, IconCalendar } from "@tabler/icons-react"
const PartnerDashboard = () => {
    return (
        <Box style={{height:'100%'}}>
            <Stack >
                <Group px={24} py={18} grow justify="space-between" align="center">
                    <Group gap={5}>
                        <Text span fw={600} fz={16} c="#5C5F66">Amazon Inc.</Text>
                        <IconChevronDown fontSize={24} color="#5C5F66" />
                    </Group>
                    <Group gap={20} justify="flex-end">
                        <Text span key="all" fw={400} fz={14} c="#5C5F66">All</Text>
                        <Text span key="today" fw={400} fz={14} c="#5C5F66">Today</Text>
                        <Text span key="lastWeek" fw={400} fz={14} c="#5C5F66">Last 7 Days</Text>
                        <Text span key="lastMonth" fw={400} fz={14} c="#5C5F66">Last 30 Days</Text>
                        <Group gap={12} wrap='nowrap'>
                            <IconCalendar size={14} color="#5C5F66" />
                            <Text span fw={400} fz={14} c="#5C5F66">Select date</Text>
                            <IconChevronDown size={14} color="#5C5F66" />
                        </Group>
                    </Group>
                </Group>
                <Stack px={24} style={{height:'100%'}} >
                    <Group grow preventGrowOverflow={false} gap={12}>
                        <Card key="total_balance" h={98} px={24} py={20} bg="#212529" c='#FFFFFF' radius={16}>
                            <Stack gap={8}>
                                <Title order={5} fw={400}>Total Balance</Title>
                                <Title order={2} >$3140.72</Title>
                            </Stack>
                        </Card>
                        <Card key="total_spending" h={98} px={24} py={20} withBorder radius={16} >
                            <Stack gap={8}>
                                <Title order={5} fw={400}>Total Spending</Title>
                                <Title order={2} >$3140.72</Title>
                            </Stack>
                        </Card>
                        <Card key="total_spending2" h={98} px={24} py={20} withBorder radius={16}>
                            <Stack gap={8}>
                                <Title order={5} fw={400}>Total Spending</Title>
                                <Title order={2} >$3140.72</Title>
                            </Stack>
                        </Card>
                        <Card key="total_spending3" h={98} px={24} py={20} withBorder radius={16}>
                            <Stack gap={8}>
                                <Title order={5} fw={400}>Total Spending</Title>
                                <Title order={2} >$3140.72</Title>
                            </Stack>
                        </Card>
                        <Card key="reporting_and_analytics" h={98} px={24} py={20} withBorder radius={16}>
                            <Stack gap={8}>
                                <Title order={5} fw={400}>Total Spending</Title>
                                <Title order={2} >$3140.72</Title>
                            </Stack>
                        </Card>
                    </Group>
                    <Group grow gap={14} align="flex-start">
                        <Stack gap={26}>
                            <Card withBorder mih={461}  radius={16}>
                            </Card>
                            <Box mt='auto'>
                                <Group justify='space-between' align='center' my={24}>
                                    <Text c="dimmed" fz={14} fw={400}>Showing 17/200 results</Text>
                                    <Pagination size="sm" total={10} withEdges siblings={0} boundaries={1} defaultValue={1} />
                                </Group>
                            </Box>
                        </Stack>
                        <Card withBorder mih={461} radius={16}></Card>
                    </Group>
                </Stack>
            </Stack>

        </Box>
    )
}

export default PartnerDashboard
export const ORGANIZATION_SIZE = {
    '1 - 100': 1,
    '100 - 500': 2,
    '500 - 1500': 3,
    '1500 - 2500': 4
}

export const EMPLOYEES_AT_SITE_SIZE = {
    '1 - 20': 1,
    '20 - 40': 2,
    '40 - 60': 3,
    '60 - 80': 4,
    '80 - 100': 5
}

export const ORGANIZATION_TYPE = {
    'Type 1': 1,
    'Type 2': 2,
    'Type 3': 3,
    'Type 4': 4,
    'Type 5': 5,
}

export const ORGANIZATION_TYPE_CHOICES = [
    { value: '1', label: 'Type 1' },
    { value: '2', label: 'Type 2' },
    { value: '3', label: 'Type 3' },
    { value: '4', label: 'Type 4' },
    { value: '5', label: 'Type 5' },

]

export const ORGANIZATION_SIZE_CHOICES = [
    { value: '1', label: '1 - 100' },
    { value: '2', label: '100 - 500' },
    { value: '3', label: '500 - 1500' },
    { value: '4', label: '1500 - 2500' },

]

export const ORGANIZATIONS_EMPLOYEES_AT_SITE_SIZE_CHOICES = [
    { value: '1', label: '1 - 20' },
    { value: '2', label: '20 - 40' },
    { value: '3', label: '40 - 60' },
    { value: '4', label: '60 - 80' },
    { value: '5', label: '80 - 100' },
] 
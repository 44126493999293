import logo from './logo.svg';
import './App.css';
import "./assets/scss/_base.scss"
import "./assets/scss/_mixins.scss"
import "./assets/scss/_utilities.scss"
import "./assets/scss/_variables.scss"
import "./assets/style.scss"
import RouterApp from './router';

function App() {
  return (
    <RouterApp />
  );
}

export default App;

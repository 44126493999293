import React from 'react';
import classes from './notification.module.css';
import { Select, Text, Textarea, Box, Stack, Title, Radio, Flex, Group, Divider, TextInput, Button } from '@mantine/core';
import { IconChevronDown, IconDeviceMobile, IconMail } from '@tabler/icons-react';
import { useState } from 'react';
import PrimaryButton from '../BaseComponents/Buttons/Button';
import { useForm } from '@mantine/form';
import mock from '../../assets/images/mock.png'
import { useMediaQuery } from '@mantine/hooks';
import VerifyEmail from './verifyEmail';
import ModalComp from '../Modal/Modal';
import { DatePickerInput } from '@mantine/dates';
import scheduleNotification from '../../assets/icons/schedule-notification.svg'

const CreatePushNotification = ({
    openScheduleNotification,
    setOpenScheduleNotification,
    sendNotification,
    setSendNotification,
    applyNotificationSetting
}) => {

    const selectIcon = <IconChevronDown style={{ width: '16px', height: '16px' }} />
    const [notificationOption, setNotificationOption] = useState('application')
    const isSmallScreen = useMediaQuery("(max-width: 768px)");
    const setValue = (event) => { setNotificationOption(event) }

    const textInputStyle = {
        label: {
            fontSize: '16px',
            marginBottom: '5px',
        }
    }

    const form = useForm({
        initialValues: {
            template: '',
            recipient: '',
            headline: null,
            message: null
        }
    })

    const handleSendNotification = (e) => {
        e.preventDefault()
    }

    return (
        <>
            {applyNotificationSetting && (
                <ModalComp
                    open={sendNotification ? false : true}
                    title={<p style={{
                        color: "#0D2029",
                        fontSize: "16px",
                        fontWeight: "700"
                    }}>Push has been scheduled!</p>}
                >
                    <img src={scheduleNotification} width="100%" alt="" />
                    <div style={{
                        borderTop: "1px solid #E3E3E3",
                        borderBottom: "1px solid #E3E3E3",
                        padding: "12px"
                    }}>
                        <p style={{
                            color: "#5C5F66",
                            fontSize: "14px",
                            fontWeight: "600"
                        }}>TUESDAY</p>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "10px"
                        }}>
                            <p style={{
                                color: "#2C2E33",
                                fontSize: "16px",
                                fontWeight: "700"
                            }}>Feb 13th, 2024 / 1:00 PM</p>
                            <p style={{
                                color: "#5C5F66",
                                fontSize: "12px",
                                fontWeight: "400"
                            }}>GMT+8:00 (PST)</p>
                        </div>
                    </div>
                    <div style={{
                        marginTop: "24px"
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}>
                            <Button onClick={() => {
                                setSendNotification(true)
                            }} style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                borderRadius: "8px",
                                marginLeft: "10px"
                            }}>Dismiss</Button>
                        </div>
                    </div>
                </ModalComp>
            )}
            <ModalComp
                open={openScheduleNotification}
                setOpen={setOpenScheduleNotification}
                title={<p style={{
                    color: "#0D2029",
                    fontSize: "16px",
                    fontWeight: "700"
                }}>Scheduled push</p>}
            >
                <Stack gap={24}>
                    <Stack>
                        <Divider />
                        <Stack gap={10}>
                            <Text>Tuesday</Text>
                            <Group justify='space-between'>
                                <Text><strong>Feb 13th, 2024/1:00 PM</strong></Text>
                                <Text>GMT+8:00 (PST)</Text>
                            </Group>
                        </Stack>
                        <Divider />
                    </Stack>
                    <Stack gap={8}>
                        <Group grow>
                            <DatePickerInput label="Date" value={new Date()} />
                        </Group>
                        <TextInput label='Headline' />
                    </Stack>
                    <Box ml='auto'>
                        <PrimaryButton text='Schedule your push' />
                    </Box>
                </Stack>

            </ModalComp>
            <Box className={classes.createPushNotificationContainer}>
                <Box style={{ width: "700px", padding: "24px 24px 24px 0" }}>
                    <form onSubmit={handleSendNotification}>
                        <Stack gap={24}>
                            <Title order={2}>Create your push notification</Title>
                            <Stack gap={20}>
                                <Stack gap={10}>
                                    <Text>Notification type</Text>
                                    <Radio.Group onChange={setValue} size="md" value={notificationOption}>
                                        <Group>
                                            <Radio.Card className="radio-choice-wrapper" key="application_choice" w={100} h={100} justify="center" align="center" value='application'>
                                                <Stack gap={12} w={100} h={100} justify="center" align="center">
                                                    <Flex w={40} h={40} bd="1px dashed" justify='center' align='center' className='radio-choice'>
                                                        <IconDeviceMobile />
                                                    </Flex>
                                                    <Text size="sm" c='dark.3'>Application</Text>
                                                </Stack>
                                            </Radio.Card>
                                            <Radio.Card className="radio-choice-wrapper" key="email_choice" w={100} h={100} value='email'>
                                                <Stack w={100} h={100} gap={12} justify="center" align="center" >
                                                    <Flex w={40} h={40} bd="1px dashed" justify='center' align='center' className='radio-choice' >
                                                        <IconMail />
                                                    </Flex>
                                                    <Text size="sm" c='dark.3'>Email</Text>
                                                </Stack>
                                            </Radio.Card>
                                        </Group>
                                    </Radio.Group>
                                </Stack>
                                <Stack>
                                    <Select label="Notification template" {...form.getInputProps('template')} rightSection={selectIcon} radius={6} styles={textInputStyle} />
                                    <Select label="Recipients" {...form.getInputProps('headline')} rightSection={selectIcon} placeholder='Select recipients' radius={6} styles={textInputStyle} />
                                    <Textarea
                                        label="Headline"
                                        placeholder='Notification headline'
                                        {...form.getInputProps('headline')}
                                        radius={6}
                                        styles={{
                                            ...textInputStyle,
                                            input: {
                                                height: '68px'
                                            }
                                        }}
                                    />
                                    <Textarea
                                        label="Message (required)"
                                        placeholder='Enter your message here'
                                        {...form.getInputProps('message')}
                                        radius={6}
                                        styles={{
                                            ...textInputStyle,
                                            input: {
                                                height: '93px'
                                            }
                                        }}
                                    />
                                </Stack>
                                <Group align='center' justify='flex-end'>
                                    <PrimaryButton type='submit' text="Send now" />
                                    <PrimaryButton text="Schedule your push" onClick={() => setOpenScheduleNotification(true)} />
                                </Group>
                            </Stack>

                        </Stack>
                    </form>
                </Box>
                <Divider orientation="vertical" h={isSmallScreen ? "93vh" : "100vh"} />
                <Box style={{ width: "calc(100% - 700px)", display: "flex", justifyContent: "center", alignItems: "baseline", padding: "60px" }}>
                    {sendNotification ? <VerifyEmail /> : <img src={mock} alt="" />}
                </Box>
            </Box>
        </>
    )
}

export default CreatePushNotification;
import { Tabs, Indicator, Text } from '@mantine/core';

function Tab({ tabsArray, children }) {

  return (
    <Tabs defaultValue="userInfo">
      <Tabs.List>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
          {tabsArray.map(tab =>
          (<Tabs.Tab className='employeeTab' fw="normal" fz={16} value={tab.value} key={tab.value} disabled={tab.disabled}>
            {tab.icon}
            <Text>{tab.name}</Text>
            {tab.indicator ? <Indicator inline processing color="#3354F4" size={12} style={{ position: "absolute", right: 3, top: 4, zIndex: 10000 }} /> : null}
          </Tabs.Tab>)
          )}
        </div>
      </Tabs.List>

      {/* adding table panels here */}
      {children}
    </Tabs>

  );
}

export default Tab;
import { Tabs, Divider, Box } from "@mantine/core"
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import React from "react";
import './TabBaseLayout.css'
const TabBaseLayout = ({ tabList = [], children }) => {


    const { height } = useViewportSize();
    const isSmallScreen = useMediaQuery("(max-width: 768px)");

    const serializedTabList = tabList.map(tab => {
        return (
            <Tabs.Tab h={50} fw="500" key={tab.value} value={tab.value}>{tab.label}</Tabs.Tab>
        )
    })

    const serializedTabPanels = React.Children.map(children, (child, index) => (
        <>
            <Tabs.Panel key={tabList[index].value} value={tabList[index].value} className='panel'>
                {child}
            </Tabs.Panel>
        </>
    ))

    return (
            <Tabs
                orientation={isSmallScreen ? "horizontal" : "vertical"}
                defaultValue={tabList.length > 0 ? tabList[0].value : ''}
                color='#E9ECEF'
                variant="pills"
                h={height - 60}
                className='tab'
                styles={(theme) => ({
                    tab: {
                        color: theme.colors.dark[7], // Default text color for inactive tabs
                        '&[dataActive]': {
                            color: theme.black, // Text color for active tab
                            backgroundColor: '#E9ECEF', // Active tab background color
                        },
                    },
                })}
            >
                <Tabs.List px={12} pt={24} style={{
                        display: "flex",
                        justifyContent: isSmallScreen ? "space-around" : "flex-start", // Center tabs on small screens
                        flexDirection: isSmallScreen ? "row" : "column", // Switch layout
                        width: !isSmallScreen && "193px",
                    }}>
                    {serializedTabList}
                </Tabs.List>

                <Divider orientation={isSmallScreen ? "horizontal" : "vertical"} h={isSmallScreen ? "auto" : "93vh"} ml={!isSmallScreen && 12} mr={!isSmallScreen && 24} my={isSmallScreen && 15} />

                {serializedTabPanels}
            </Tabs>
    )
}

export default TabBaseLayout
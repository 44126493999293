import { IconCheck, IconMinus } from "@tabler/icons-react"
import { Group } from "@mantine/core"
import EmployStatus from "../components/EmployeeManagement/EmployStatus"

export const EMPLOYEE_TIER = {
    '1': 'Silver tier',
    '2': 'Bronze tier',
    '3': 'Gold tier'
}

export const EMPLOYEE_TIER_WITH_ICONS = {
    '1':
        <>
            <Group gap="6px" align="center" justify="center">
                <div style={{
                    width: "14px",
                    height: '14px',
                    background: 'linear-gradient(137.95deg, #7A96AC 2.28%, #EAEFF3 19.8%, #C2D4E1 32.94%, #FFFFFF 50.16%, #D4DEE5 62.15%, #ABBDC8 78.69%, #BCCAD7 95.24%)',
                    borderRadius: '4px',
                }}
                />
                <p style={{fontSize:'0.8rem', fontWeight:'400'}}>Silver tier</p>
            </Group>
        </>
    ,
    '2':
        <>
            <Group gap="6px" align="center" justify="center">
                <div style={{
                    width: "14px",
                    height: '14px',
                    background: 'linear-gradient(135.31deg, #9E8976 15.43%, #7A5E50 30.62%, #F6D0AB 47.37%, #9D774E 62.96%, #C99B70 82.05%, #795F52 93.35%)',
                    borderRadius: '4px',
                }}
                />
                <p style={{fontSize:'0.8rem', fontWeight:'400'}}>Bronze tier</p>
            </Group>
        </>
    ,
    '3':
        <>
            <Group gap="6px" align="center" justify="center">
                <div style={{
                    width: "14px",
                    height: '14px',
                    background: 'linear-gradient(250.62deg, #8C421D 13.98%, #FBE67B 37.66%, #FCFBE7 52.96%, #F7D14E 70.02%, #D4A041 86.77%)',
                    borderRadius: '4px',
                }}
                />
                <p style={{fontSize:'0.8rem', fontWeight:'400'}}>Gold tier</p>
            </Group>
        </>
}

export const EMPLOYEE_TIER_CHOICES = [
    { value: '3', label: 'Gold tier' },
    { value: '1', label: 'Silver tier' },
    { value: '2', label: 'Bronze tier' },
]

export const EMPLOYEE_PRESCRIPTION = {
    '1': 'Prescription',
    '2': 'Non-Prescription'
}

export const EMPLOYEE_STATUS = {
    '1': 'Invited',
    '2': 'Error',
    '3': 'Active'
}

export const EMPLOYEE_STATUS_BLOCK = {
    '1':
        <>
            <EmployStatus status="Invited" className="invited" />
        </>,
    '2':
        <>
            <EmployStatus status="Error" className="error" />
        </>,
    '3':
        <>
            <EmployStatus status="Active" className="active" />
        </>
}

export const EMPLOYEE_FRAME_AND_LENS = {
    true:
        <>
            <IconCheck />
        </>,
    false:
        <>
            <IconMinus color="#ADB5BD"/>
        </>
}


export const EMPLOYEE_TABLE_HEADERS = [
    { value: 'name', label: 'Name' },
    { value: 'email', label: 'Email' },
    { value: 'tier', label: 'Tier Assignment' },
    { value: 'address', label: 'Site/Location' },
    { value: 'rx_non_rx', label: 'Rx/ Non-Rx' },
    { value: 'frame_and_lens', label: 'Frames & lens' },
    { value: 'status', label: 'Status' },
    { value: 'actions', label: 'Actions' },
]

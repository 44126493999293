import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    countries:[]
};

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setCountries: (state, {payload}) => { state.countries = payload.map((country)=>{return {'value':country.code, 'label':country.name}})}
    },
});

export const { setCountries } = commonSlice.actions;
export default commonSlice.reducer;
import React, { useEffect, useState } from 'react';
import { Text, Group } from '@mantine/core';
import classes from '../pages.module.css'
import logo from "../../assets/icons/rfxn-logo.svg";
import Steps from '../../components/Steps/Steps';
import { AuthenticationForm, AuthenticationForm3, AuthenticationForm4, AuthenticationForm5, AuthenticationForm6 } from '../../components/Form/Forms';
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import safetyVisionLogo from '../../assets/icons/safety-vision.svg'
import hippaLogo from '../../assets/icons/hippa.svg'
import awsLogo from '../../assets/icons/aws-logo.svg'

const Registration = () => {

  const [step, setStep] = useState(1);
  const navigate = useNavigate()
  const [sendVerificationCode, setSendVerificationCode] = useState(false)

  const [width, setWidth] = useState(window.innerWidth)
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password1: '',
    password2: ''
  })
  const [personalDetails, setPersonalDetails] = useState({
    name: '',
    age: '',
    image: null,
    unFormatted: null
  })
  const [companyDetails, setCompanyDetails] = useState({
    name: '',
    size: '',
    site_name: '',
    employees_at_site: '',
    logo: null
  })
  const [teamDetails, setTeamDetails] = useState({
    file: null,
  })

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ overflow: 'hidden', display: "flex", height: "100%", flexDirection: width < 991 ? "column" : "row" }}>
      <div className={classes.bg} style={{ width: width < 991 ? "100%" : width < 1025 ? "388px" : "645px", minHeight: width < 991 ? "fit-content" : "100vh" }}>
        <div>
          <Text style={{ color: "#5C5F66" }}>Powered By</Text>
          <img src={logo} alt='' style={{ marginBottom: '65.58px' }} />
        </div>
        <Steps step={step} width={width} />
        <div className={classes.sideBarFooterLogo}>
          <img src={hippaLogo} alt="" />
          <img src={awsLogo} alt="" />
        </div>
        <div className={width < 768 ? classes.signInBackToLanding2 : classes.signInBackToLanding}>
          <p className={classes.backToLanding} onClick={() => step > 1 && setStep((step) => step - 1)}>Back</p>
          <p className={classes.usVeteran}>U.S Veteran Owned and Operated</p>
          <p className={classes.signIn} onClick={() => navigate('/')}>Sign in</p>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', position: "relative", alignItems: 'center', flexDirection: "column", marginTop: width < 991 ? "60px" : "", marginBottom: width < 991 ? "60px" : "", width: "100%" }}>
        {step <= 5 && <img src={safetyVisionLogo} alt="" />}
        {step === 1 && <AuthenticationForm sendVerificationCode={sendVerificationCode} setSendVerificationCode={setSendVerificationCode} setStep={setStep} formFields={loginDetails} setFormFields={setLoginDetails} setUser={setPersonalDetails} />}
        {/* {step === 2 && <AuthenticationForm2 setStep={setStep} step={step} timer={timer} />} */}
        {step === 2 && <AuthenticationForm3 setStep={setStep} formFields={personalDetails} setFormFields={setPersonalDetails} />}
        {step === 3 && <AuthenticationForm4 setStep={setStep} formFields={companyDetails} setFormFields={setCompanyDetails} />}
        {step === 4 && <AuthenticationForm5 setStep={setStep} formFields={teamDetails} setFormFields={setTeamDetails} />}
        {step === 5 && <AuthenticationForm6 />}
        {step > 3 && step < 5 ? <Text pos="absolute" bottom={width < 991 ? -20 : 45} c="#5C5F66" mx='auto' fw="bold" onClick={() => setStep((step) => step + 1)} style={{ cursor: 'pointer' }}>Skip now</Text> : null}
        {/* position: width < 991 ? "inherit" : "absolute", bottom: width < 991 ? "0" : "3rem" */}
        {/* <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "0 50px", marginTop: 'auto', marginBottom: '20px' }}>
          {step > 1 ?
            (
              <>
                <Group gap="6" style={{ color: "#5C5F66", fontWeight: "700", cursor: "pointer" }}
                  onClick={navigateBackwards}>
                  <IoIosArrowBack style={{ width: '24', height: '24' }} />
                  <span>Back</span>
                </Group>
                {step === 4 ? <><Text mx='auto' onClick={() => { setStep((step) => step + 1) }} style={{ cursor: 'pointer' }}>Skip now</Text> <p style={{ color: "#5C5F66", fontWeight: "700" }}>Step 0{step}</p></> : step === 5 ? <p style={{ color: "#5C5F66", fontWeight: "700", }}>Finish</p> : <p style={{ color: "#5C5F66", fontWeight: "700", }}>Step 0{step}</p>}
              </>
            ) :
            <p style={{ color: "#5C5F66", fontWeight: "700" }}>Step 0{step}</p>
          }
        </div> */}
      </div>
    </div>
  );
};

export default Registration;
import React from 'react';
import classes from './notification.module.css'
import notificationIcon from '../../assets/icons/notification-icon.png'
import { Button, Input, Menu } from '@mantine/core';
import { FiFilter } from 'react-icons/fi';
import { IoSearch } from 'react-icons/io5';
import { BsThreeDots } from "react-icons/bs";

const ScheduleNotificationCard = ({ width }) => {
    return (
        <div className={classes.scheduleNotificationCard} style={{
            marginBottom: width < 991 ? "20px" : ""
        }}>
            <div style={{
                padding: "12px" 
            }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                    <img src={notificationIcon} alt='' />
                    <p className={classes.scheduleNotificationCardTitle}>RFXN</p>
                </div>
                <p className={classes.scheduleNotificationCardTitle2}>Confirm your choose!</p>
                <p className={classes.scheduleNotificationCardDesc}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
            </div>
            <div className={classes.recipient}>
                <p style={{ fontSize: "14px", color: "#868E96", fontWeight: "400" }}> Recipients: <span style={{ color: '#101113' }}>All new users</span> </p>
            </div>
            <div className={classes.type}>
                <p style={{ fontSize: "14px", color: "#868E96", fontWeight: "400" }}> Type: <span style={{ color: '#101113' }}>All new users</span> </p>
            </div>
            <div className={classes.type}>
                <p style={{ fontSize: "14px", color: "#FD7E14", fontWeight: "400" }}>Tue Februrary 13th 2024</p>
                <p style={{ fontSize: "14px", color: "#868E96", fontWeight: "400" }}> 10:21 AM </p>

                <Menu shadow="md" width={200}>
                    <Menu.Target>
                        <button style={{
                            backgroundColor: "transparent",
                            margin: 0,
                            padding: 0,
                            border: "none"
                        }}>
                            <BsThreeDots color="#868E96" />
                        </button>
                    </Menu.Target>

                    <Menu.Dropdown>
                        <Menu.Item fw={600} color="#495057">
                            Edit
                        </Menu.Item>
                        <Menu.Item fw={600} color="#495057">
                            Delete
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>

            </div>
        </div>
    )
}

const ScheduleNotification = ({ width }) => {
    return (
        <div className={classes.scheduleNotification}>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
                <p className={classes.scheduleNotificationTitle}>All Scheduled Notifications</p>
                <div style={{ display: "flex", position: "relative" }}>
                    <IoSearch size={17} style={{ position: "absolute", top: "25%", zIndex: 1000, left: 10, color: "#ADB5BD" }} />
                    <Input placeholder="Search" className={classes.searchNotifications} />
                    <Button style={{
                        backgroundColor: '#E9ECEF',
                        border: "2px solid #ADB5BD",
                        borderRadius: "6px",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#343A40",
                        marginLeft: "10px",
                    }}>
                        <FiFilter style={{ marginRight: 10, color: "#ADB5BD" }} /> Filter
                    </Button>
                </div>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "24px",
                flexDirection: width < 991 ? "column" : "row"
            }}>
                <ScheduleNotificationCard width={width} />
                <ScheduleNotificationCard width={width} />
                <ScheduleNotificationCard width={width} />
            </div>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "24px",
                flexDirection: width < 991 ? "column" : "row"
            }}>
                <ScheduleNotificationCard width={width} />
                <ScheduleNotificationCard width={width} />
                <ScheduleNotificationCard width={width} />
            </div>
        </div>
    )
}

export default ScheduleNotification;
import { AppShell, Box, Group, Text, Image, Stack, Divider, Burger, CloseButton } from "@mantine/core"
import { Outlet } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import BaseHeader from "./BaseHeader"
import BaseSidebar from "./BaseSidebar"
import { IconChevronDown } from "@tabler/icons-react"
import './BaseSidebar.css'
import hoyaFooterImage from '../../assets/images/hoya-sidebar-footer-image.png'
import OrganizationListMenu from "../Menu/OrganizationListMenu/OrganizationListMenu"
import { useEffect, useState } from "react"
import { fetchPartnerOrganization } from "../../middlewares/partner/partners.services"
import { fetchOrganizationList } from "../../middlewares/organization/organization"
import { fetchUser } from "../../middlewares/auth/auth"
import { useDisclosure, useMediaQuery } from "@mantine/hooks"


const BaseLayout = () => {

    const dispatch = useDispatch()
    const organization = useSelector(state => state.organizations.organization)
    const [showOrganizationList, setShowOrganizationList] = useState(false)
    const [opened, { toggle }] = useDisclosure();
    const isSmallScreen = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
        dispatch(fetchUser())
        dispatch(fetchPartnerOrganization())
        dispatch(fetchOrganizationList())
    }, [])

    function openOrganizationListMenu() {
        setShowOrganizationList(showOrganizationList => !showOrganizationList)
    }

    return (
        <>
            <AppShell
                layout="alt"
                header={{ height: 60 }}
                navbar={{
                    width: 224,
                    breakpoint: 'sm',
                    collapsed: { mobile: !opened },
                }}
                style={{ overflow: 'hidden' }}
            >
                <AppShell.Header px={24}>
                    <BaseHeader opened={opened} toggle={toggle} />
                </AppShell.Header>
                <AppShell.Navbar bg='#212529' withBorder={false} >
                    {/* patner organization or admin organization */}
                    <AppShell.Section>
                        <Box h={60} mb="auto" px='md' className="company-selection-menu">
                            <Box style={{ display: "flex" }}>
                                {organization?.logo ?
                                    <Image src={organization?.logo} fit="contain" w={23} h={23} />
                                    :
                                    <Box w={23} h={23} bg={organization?.color} style={{ borderRadius: '4px' }} />
                                }
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                    <Text span c="white" mr={15}>{organization?.name}</Text>
                                    <IconChevronDown
                                        size={16}
                                        color="white"
                                        onClick={openOrganizationListMenu}
                                        className={showOrganizationList ? 'icon-chevron-rotated' : 'icon-chevron'} />
                                </Box>
                            </Box>
                            {opened && isSmallScreen ? <CloseButton m={5} onClick={opened && toggle} /> : null}
                        </Box>
                    </AppShell.Section>

                    {
                        showOrganizationList ?
                            <AppShell.Section grow>
                                <OrganizationListMenu />
                            </AppShell.Section>
                            :
                            <AppShell.Section mt={20} px='sm' grow><BaseSidebar /></AppShell.Section>
                    }
                    {/* footer image */}
                    <AppShell.Section><Image src={hoyaFooterImage} fit="contain" /></AppShell.Section>
                </AppShell.Navbar>
                <AppShell.Main style={{ overflow: 'auto' }}>
                    <Outlet />
                </AppShell.Main>
            </AppShell>
        </>
    )
}

export default BaseLayout
import {Group, Stack, Title, Text, Box, TextInput, Loader, Button} from '@mantine/core'
import { useState } from 'react'

const OrganizationBrandColorForm = ({ form, title = "Brand your Partner Account" }) => {

    const [loading, setloading] = useState(false)
    const colorArray = ['#FA5252', '#E64980', '#BE4BDB', '#7950F2', '#4C6EF5', '#3354F4', '#15AABF', '#12B886']
    const colorPallet = colorArray.map(color => {
        return <Box key={color} w={38} h={38} bg={color} className="color-box"
            onClick={() => { form.setFieldValue('color', color) }}
            style={{ cursor: 'pointer' }}
        />
    })


    return (
        <>
            <Stack align="flex-start" justify="flex-start" gap={40} style={{ height: '100%' }}>
                <Stack gap={12}>
                    <Title order={3}>{title}</Title>
                    <Text span c="dimmed" fz={16}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                </Stack>
                <Stack>
                    <Title order={5}>Choose your accent color</Title>
                    <Group gap={10}>
                        {colorPallet}
                    </Group>
                    <Group gap={10} justify="flex-start" align="center">
                        <Text>Custom color:</Text>
                        <TextInput w={92}  {...form.getInputProps('color')} />
                        <Box w={38} h={38} bg={form.values.color} className="color-box"></Box>
                    </Group>
                </Stack>
            </Stack>
        </>
    )
}

export default OrganizationBrandColorForm
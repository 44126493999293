import { Table, Checkbox, Group, Box, Text } from '@mantine/core';
import './Table.css'

function TableComp({ type, tableHeaders, data = [], isFilterApplied, fetchEmployeeLoader, setExportCSVData, totalRecords = 0, totalPages = 0, changePage }) {

  const rows = data.map(row => {
    return (
      <Table.Tr key={row.id}>
        <Table.Td key={`checkbox${row.id}`}>
          <Checkbox aria-label="Select row" checked={row.checked} onChange={() => { }} />
        </Table.Td>
        {tableHeaders.map(header => {
          return (
            <Table.Td key={header.value}>
              <Group>
                {row[header.value]}
              </Group>
            </Table.Td>
          )
        })
        }
      </Table.Tr>
    )
  })

  return (
    <>
      <Box className="table-wrapper">
        <Table withColumnBorders highlightOnHover >
          <Table.Thead className='tableHead'>
            <Table.Tr>
              <Table.Th>
                <Checkbox aria-label="Select row" checked={data.length > 0 && data.every(row => row.checked)} onChange={() => { }} />
              </Table.Th>
              {tableHeaders.map(header => {
                return <Table.Th key={header.value} >{header.label}</Table.Th>
              })}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {data.length > 0 ? rows : []}
          </Table.Tbody>
        </Table>
      </Box>

    </>
  );
}

export default TableComp;
import { Box, Stack, Text, TextInput, Group, Radio, Textarea, Flex } from "@mantine/core"
import { useForm } from '@mantine/form';
import './EmployeeMessage.css'
import { IconDeviceMobile, IconMail } from "@tabler/icons-react";
import { useState } from "react";
import PrimaryButton from "../../BaseComponents/Buttons/Button"
const EmployeeMessage = ({ employee }) => {

    const [notificationOption, setNotificationOption] = useState('application')

    const form = useForm({
        initialValues: {
            recipient: employee?.name,
            id: employee?.id,
            headline: null,
            message: null
        }
    })

    const textInputStyle = {
        label: {
            fontSize: '18px',
            marginBottom: '10px'
        }
    }

    const setValue = (event) => {
        setNotificationOption(event)
    }

    return (
        <Box>
            <Stack gap={20} justify="flex-start" mx={24}>
                <Stack>
                    <Radio.Group onChange={setValue} label="Notification type" size="md" value={notificationOption}>
                        <Group>
                            <Radio.Card className="radio-choice-wrapper" key="application_choice" w={100} h={100} justify="center" align="center" value='application'>
                                <Stack gap={12} w={100} h={100} justify="center" align="center">
                                    <Flex w={40} h={40} bd="1px dashed" justify='center' align='center' className='radio-choice'>
                                        <IconDeviceMobile />
                                    </Flex>
                                    <Text size="sm" c='dark.3'>Application</Text>
                                </Stack>
                            </Radio.Card>
                            <Radio.Card className="radio-choice-wrapper" key="email_choice" w={100} h={100} value='email'>
                                <Stack w={100} h={100} gap={12} justify="center" align="center" >
                                    <Flex w={40} h={40} bd="1px dashed" justify='center' align='center' className='radio-choice' >
                                        <IconMail />
                                    </Flex>
                                    <Text size="sm" c='dark.3'>Email</Text>
                                </Stack>
                            </Radio.Card>
                        </Group>
                    </Radio.Group>
                </Stack>
                <TextInput
                    label="Recipient"
                    radius={6}
                    {...form.getInputProps('recipient')}
                    styles={textInputStyle}
                    readOnly
                />
                <Textarea
                    label="Headline"
                    placeholder="Notification Headline"
                    size="md"
                    radius={6}
                    {...form.getInputProps('headline')}
                    styles={{
                        ...textInputStyle,
                        input: {
                            height: '68px'
                        }
                    }}
                />
                <Textarea
                    label="Message (required)"
                    placeholder="Please enter your message here"
                    size="md"
                    radius={6}
                    {...form.getInputProps('message')}
                    styles={{
                        ...textInputStyle,
                        input: {
                            height: '93px'
                        }
                    }}
                />
                <Group gap={10} ml='auto'>
                    <PrimaryButton text="Send now" />
                    <PrimaryButton text="Schedule your push" />
                </Group>
            </Stack>
        </Box>
    )
}
export default EmployeeMessage
import { Box, Tabs, Divider } from "@mantine/core"
import { useViewportSize } from '@mantine/hooks';
import PartnerCompanyProfile from "../../components/Settings/PartnerOrganizationProfile";
const PartnerSettings = () => {

    const { height } = useViewportSize();

    return (
        <Box h={height-60} >
            <Tabs
                orientation="vertical"
                defaultValue='company_profile'
                color='#E9ECEF' 
                variant="pills" 
                h={height-60}
                styles={(theme) => ({
                    tab: {
                        color: theme.colors.dark[7], // Default text color for inactive tabs
                        '&[dataActive]': {
                            color: theme.black, // Text color for active tab
                            backgroundColor: '#E9ECEF', // Active tab background color
                        },
                    },
                })}
            >
                <Tabs.List px={12} pt={24}>
                    <Tabs.Tab key='company_profile' value='company_profile'>Company Profile</Tabs.Tab>
                    <Tabs.Tab key='security_settings' value='security_settings'>Security settings</Tabs.Tab>
                </Tabs.List>

                <Divider orientation="vertical"/>

                <Tabs.Panel value="company_profile">
                    <PartnerCompanyProfile />
                </Tabs.Panel>
                <Tabs.Panel pl={19} pt={20} value="security_settings">
                    Security settings
                </Tabs.Panel>
            </Tabs>

        </Box>
    )
}

export default PartnerSettings
import React from 'react';
import classes from './notification.module.css'
import { Button } from '@mantine/core';
import twitter from '../../assets/icons/twitter.svg';
import facebook from '../../assets/icons/facebook.svg';
import instagram from '../../assets/icons/instagram.svg';
import linkedin from '../../assets/icons/linkedin.svg';

const VerifyEmail = () => {
    return (
        <div className={classes.verifyEmailContainer}>
            <div className={classes.verifyEmailHeader}></div>
            <div className={classes.verifyEmailBody}>
                <p className={classes.verifyBodyTitle}>Verify your email</p>
                <p className={classes.verifyBodyMessage}>Hello Prince, Please click the button below to verify your email address.</p>
                <Button style={{
                    backgroundColor: "#0065F5",
                    fontSize: "16px",
                    fontWeight: "800",
                }}>Verify Email address</Button>
                <p className={classes.regards}>Regards, Chunk</p>
            </div>
            <div className={classes.verifyEmailFooter}>
                <div className={classes.iconContainer}>
                    <img width={22} src={twitter} alt="" />
                    <img width={22} src={facebook} alt="" />
                    <img width={22} src={instagram} alt="" />
                    <img width={22} src={linkedin} alt="" />
                </div>
                <p style={{
                    color: "#828282",
                    fontSize: "14px",
                    fontWeight: "500"
                }}>
                    If you’re having trouble clicking the "Verify Email Address" button, copy and paste the
                    URL below into your web browser: <a href='#'>
                        https://app.chunk.africa/verify/133/d448889b9eeeb40
                        c2a8b63e53046b5f4b402f39b?expires=1643281321&signature=6d6b01c06b6451757
                        7623f1c240094948313757b76250408b41bc901badef2ef
                    </a>
                </p>
            </div>
        </div>
    )
}

export default VerifyEmail;
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";
import slide1 from "../../assets/images/partner-signup-bg-slide1.png";
import slide2 from "../../assets/images/partner-signup-bg-slide2.png";
import slide3 from "../../assets/images/partner-signup-bg-slide3.png";
import { Box, Center, Text } from "@mantine/core";

export default function SimpleSlider() {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    };
    return (
        <div className="slider-container" style={{ height: "calc(100vh - 48px)" }}>
            <Slider {...settings}>
                <div style={{ height: "calc(100vh - 48px)" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            background: 'tran',
                            backgroundImage: `linear-gradient(179deg, rgba(0, 0, 0, 1.50), rgb(255 255 255 / 15%), rgba(0, 0, 0, 1.50)), url(${slide1})`,
                            backgroundPositionX: "center",
                            backgroundPositionY: "center",
                            height: "calc(100vh - 48px)",
                            backgroundSize: "cover",
                            padding: "75px 20px 80px",
                        }}
                    >
                        <Box ta={Center} mx={'auto'} maw={700}>
                            <Text size={'40px'} fw={700} color={'#FFFFFF'} mb={'6px'} lh={1.17} ta={'center'}>Welcome to the Refraxion!</Text>
                            <Text size={'22px'} fw={300} color={'#FFFFFF'} lh={0.8} ta={'center'}>Partnership Program</Text>
                        </Box>
                        <Box ta={Center} mx={'auto'} maw={700}>
                            <Text size={'40px'} fw={700} color={'#FFFFFF'} mb={'6px'} lh={1.17} ta={'center'}>Simplified Occupational Health and Safety Solutions.</Text>
                            <Text size={'20px'} fw={300} color={'#FFFFFF'} lh={1.4} ta={'center'}>Effortlessly collaborate with your employees <br/> and customers in real-time</Text>
                        </Box>
                    </div>
                </div>
                <div style={{ height: "calc(100vh - 48px)" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            backgroundImage: `linear-gradient(179deg, rgba(0, 0, 0, 1.50), rgb(255 255 255 / 15%), rgba(0, 0, 0, 1.50)), url(${slide2})`,
                            backgroundPositionX: "center",
                            backgroundPositionY: "center",
                            height: "calc(100vh - 48px)",
                            backgroundSize: "cover",
                            padding: "75px 20px 80px",
                        }}
                    >
                        <Box ta={Center} mx={'auto'}  maw={619}>
                            <Text size={'40px'} fw={700} color={'#FFFFFF'} mb={'6px'} lh={1.17} ta={'center'}>We believe creative solutions simplify complex problems.</Text>
                            {/* <Text size={'22px'} fw={300} color={'#FFFFFF'} lh={0.8} ta={'center'}>Partnership Program</Text> */}
                        </Box>
                        <Box ta={Center} mx={'auto'}  maw={619}>
                            {/* <Text size={'40px'} fw={700} color={'#FFFFFF'} mb={'6px'} lh={1.17} ta={'center'}>Seamless Collaboration</Text> */}
                            <Text size={'20px'} fw={300} color={'#FFFFFF'} lh={1.4} ta={'center'}>Effortlessly collaborate with your employees <br/> and customers in real-time</Text>
                        </Box>
                    </div>
                </div>
                <div style={{ height: "calc(100vh - 48px)" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            backgroundImage: `linear-gradient(179deg, rgba(0, 0, 0, 0.40), rgb(255 255 255 / 15%), rgba(0, 0, 0, 0.40)), url(${slide3})`,
                            backgroundPositionX: "center",
                            backgroundPositionY: "center",
                            height: "calc(100vh - 48px)",
                            backgroundSize: "cover",
                            padding: "75px 20px 80px",
                        }}
                    >
                        <Box ta={Center} mx={'auto'} maw={471}>
                            <Text size={'40px'} fw={700} color={'#FFFFFF'} mb={'6px'} lh={1.17} ta={'center'}>It's an honor to have you join the team</Text>
                            {/* <Text size={'22px'} fw={300} color={'#FFFFFF'} lh={0.8} ta={'center'}>Partnership Program</Text> */}
                        </Box>
                        <Box ta={Center} mx={'auto'} maw={471}>
                            {/* <Text size={'40px'} fw={700} color={'#FFFFFF'} mb={'6px'} lh={1.17} ta={'center'}>Simplified Solutions</Text> */}
                            <Text size={'20px'} fw={300} color={'#FFFFFF'} lh={1.4} ta={'center'}>Effortlessly collaborate with your employees <br/> and customers in real-time</Text>
                        </Box>
                    </div>
                </div>
            </Slider>
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import { Grid, Divider, Box, Stack, Group, Text, Button, Avatar, GridCol } from '@mantine/core';
import { LineChart } from '@mantine/charts';
import DashboardCard from '../../components/Dashboard/card';
import user from '../../assets/icons/Document.svg'
import { FiSun } from "react-icons/fi";
import { IconPlus, IconUsers, IconCalendarMonth, IconChartLine, IconBuilding, IconTableRow } from '@tabler/icons-react';
import { FaCaretDown } from "react-icons/fa";


const Dashboard = () => {

  const [width, setWidth] = useState(window.innerWidth);

  function handleResize() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  console.log(width);

  const data = [
    {
      date: 'Mar 22',
      Apples: 2890,
      Oranges: 2338,
      Tomatoes: 2452,
    },
    {
      date: 'Mar 23',
      Apples: 2756,
      Oranges: 2103,
      Tomatoes: 2402,
    },
    {
      date: 'Mar 24',
      Apples: 3322,
      Oranges: 986,
      Tomatoes: 1821,
    },
    {
      date: 'Mar 25',
      Apples: 3470,
      Oranges: 2108,
      Tomatoes: 2809,
    },
    {
      date: 'Mar 26',
      Apples: 3129,
      Oranges: 1726,
      Tomatoes: 2290,
    },
  ];

  return (
    <>
      <Box style={{height:'100%'}}>
        <Group px={33} py={16.5}>
          <Group mr="auto">
            <Group>
              <Group gap="2px">
                <IconCalendarMonth />
                <Text>This Week</Text>
                <FaCaretDown />
              </Group>
              <Divider orientation="vertical" />
              <Group gap="2px">
                <IconChartLine />
                <Text>Compare to</Text>
                <FaCaretDown />
              </Group>

              {/* <Text>Compare to</Text> */}
            </Group>
          </Group>
          <Group ml="auto">
            <Group>
              <Group gap="2px">
                <IconBuilding />
                <Text>Fields to display</Text>
                <FaCaretDown />
              </Group>
              <Divider orientation="vertical" />
              <Group gap="2px">
                <IconTableRow />
                <FaCaretDown />
              </Group>
              {/* <Text>Compare to</Text> */}
            </Group>
          </Group>
        </Group>
        <Divider></Divider>
        <Stack justify='center' align='center' gap="45px" px={33} py={27}>
          <Grid w="100%" gutter={{ base: 5, xs: 'md', md: 'xl', xl: 45 }}>
            <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
              <DashboardCard maxHeight='302px'>
                <Stack h={302} gap="lg" justify='space-around' align="center">
                  <Group >
                    <FiSun style={{ fontSize: '49px', color: '#C8CAD5' }} />
                    <Stack gap="xs" justify='center' align='flex-start'>
                      <Text c='dimmed' size='xl' mb="-10">8:02:09 AM</Text>
                      <Text c='dimmed' size='xs' >Realtime Insight</Text>
                    </Stack>
                  </Group>
                  <Stack gap="xs">
                    <Text mb="-10"><strong>Today:</strong></Text>
                    <Text><strong>18 November 2023</strong></Text>
                  </Stack>
                  <Button radius="md" color="#3354F4">View Attendance</Button>
                </Stack>
              </DashboardCard>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 9, lg: 9 }}>
              <Grid w="100%" gutter={{ base: 5, xs: 'md', md: 'xl', xl: 45 }}>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <DashboardCard maxHeight='132px'>
                    <Stack gap="5px">
                      <Group>
                        <Text mr='auto'><h1><strong>425</strong></h1></Text>
                        <Avatar src={null} alt="no image here" color="#3354F4">
                          <IconUsers></IconUsers>
                        </Avatar>
                      </Group>
                      <Stack gap='1px'>
                        <Text>Total Employees</Text>
                        <Group gap="2px" align='center'>
                          <Avatar color="#97CE71" size={15}><IconPlus /></Avatar>
                          <Text c='dimmed'>2 new employees added!</Text>
                        </Group>
                      </Stack>
                    </Stack>
                  </DashboardCard>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <DashboardCard maxHeight='132px'>
                    <Stack gap="5px">
                      <Group>
                        <Text mr='auto'><h1><strong>425</strong></h1></Text>
                        <Avatar src={null} alt="no image here" color="#3354F4">
                          <IconUsers></IconUsers>
                        </Avatar>
                      </Group>
                      <Stack gap='1px'>
                        <Text>Total Employees</Text>
                        <Group gap="2px" align='center'>
                          <Avatar color="#97CE71" size={15}><IconPlus /></Avatar>
                          <Text c='dimmed'>2 new employees added!</Text>
                        </Group>
                      </Stack>
                    </Stack>
                  </DashboardCard>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <DashboardCard maxHeight='132px'>
                    <Stack gap="5px">
                      <Group>
                        <Text mr='auto'><h1><strong>425</strong></h1></Text>
                        <Avatar src={null} alt="no image here" color="#3354F4">
                          <IconUsers></IconUsers>
                        </Avatar>
                      </Group>
                      <Stack gap='1px'>
                        <Text>Total Employees</Text>
                        <Group gap="2px" align='center'>
                          <Avatar color="#97CE71" size={15}><IconPlus /></Avatar>
                          <Text c='dimmed'>2 new employees added!</Text>
                        </Group>
                      </Stack>
                    </Stack>
                  </DashboardCard>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <DashboardCard maxHeight='132px'>
                    <Stack gap="5px">
                      <Group>
                        <Text mr='auto'><h1><strong>425</strong></h1></Text>
                        <Avatar src={null} alt="no image here" color="#3354F4">
                          <IconUsers></IconUsers>
                        </Avatar>
                      </Group>
                      <Stack gap='1px'>
                        <Text>Total Employees</Text>
                        <Group gap="2px" align='center'>
                          <Avatar color="#97CE71" size={15}><IconPlus /></Avatar>
                          <Text c='dimmed'>2 new employees added!</Text>
                        </Group>
                      </Stack>
                    </Stack>
                  </DashboardCard>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <DashboardCard maxHeight='132px'>
                    <Stack gap="5px">
                      <Group>
                        <Text mr='auto'><h1><strong>425</strong></h1></Text>
                        <Avatar src={null} alt="no image here" color="#3354F4">
                          <IconUsers></IconUsers>
                        </Avatar>
                      </Group>
                      <Stack gap='1px'>
                        <Text>Total Employees</Text>
                        <Group gap="2px" align='center'>
                          <Avatar color="#97CE71" size={15}><IconPlus /></Avatar>
                          <Text c='dimmed'>2 new employees added!</Text>
                        </Group>
                      </Stack>
                    </Stack>
                  </DashboardCard>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <DashboardCard maxHeight='132px'>
                    <Stack gap="5px">
                      <Group>
                        <Text mr='auto'><h1><strong>425</strong></h1></Text>
                        <Avatar src={null} alt="no image here" color="#3354F4">
                          <IconUsers></IconUsers>
                        </Avatar>
                      </Group>
                      <Stack gap='1px'>
                        <Text>Total Employees</Text>
                        <Group gap="2px" align='center'>
                          <Avatar color="#97CE71" size={15}><IconPlus /></Avatar>
                          <Text c='dimmed'>2 new employees added!</Text>
                        </Group>
                      </Stack>
                    </Stack>
                  </DashboardCard>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
          {/* <Grid w="100%" gutter={{ base: 5, xs: 'md', md: 'xl', xl: 31 }}>
            <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
              <DashboardCard maxHeight='303px'>
                <LineChart
                  h={303}
                  data={data}
                  dataKey="date"
                  withLegend
                  series={[
                    { name: 'Apples', color: 'indigo.6' },
                  ]}
                />
              </DashboardCard>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
              <DashboardCard maxHeight='303px'></DashboardCard>
            </Grid.Col>
          </Grid> */}
        </Stack>
      </Box>
     
    </>
   
  );
};

export default Dashboard;

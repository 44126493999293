import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: {},
    token: null,
    googleCredentials: null,
    googleRedirectCode: null
};

export const authSlice = createSlice({
    name: "Auth",
    initialState,
    reducers: {
        logout: (state) => {
            state.user = {};
            state.token = null;
        },
        setUser: (state, { payload }) => {
            return { ...state, user: payload }
        },
        setToken: (state, { payload }) => {
            return { ...state, token: payload }
        },
        setGoogleCredentials: (state, { payload }) => {
            state.googleCredentials = payload
        },
        setGoogleRedirectCode: (state, {payload}) =>{
            state.googleRedirectCode = payload
        },
        updateUserProfile: (state, {payload}) =>{  state.user.profile = {...payload} }

    },
});

export const { logout, setUser, setToken, setGoogleCredentials, updateUserProfile } = authSlice.actions;
export default authSlice.reducer;
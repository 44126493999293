import React from 'react';
import classes from './safetyAwards.module.css';
import avatar from '../../assets/images/avatar.jpeg'
import { Checkbox } from '@mantine/core';

const Employess = () => {
    return (
        <div className={classes.safetyEmployeeContainer}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <img style={{ width: "32px", height: "32px", borderRadius: "100%", objectFit: "cover" }} src={avatar} />
                <div style={{ display: "flex", justifyContent: "space-between", width:"100%" }}>
                    <div className={classes.nameProfContainer}>
                        <p>Jennifer Lopez</p>
                        <p>Product Designer, Integrations</p>
                    </div>
                    <div>
                        <Checkbox />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Employess
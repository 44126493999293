import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    organization: null,
    organizationList: [],
    selectedOrganization: null
};

export const organizationSlice = createSlice({
    name: "Organization",
    initialState,
    reducers: {
        setOrganization: (state, { payload }) => {
            state.organization = payload
        },
        updateOrganizationDetail: (state, { payload }) => {
            state.organization = payload
        },
        setOrganizationList: (state, { payload }) => {
            //checks for duplicates and updates them else push the new organization
            payload.forEach(organization => {
                const existingOrganizationPosition = state.organizationList.findIndex(foundOrganization => foundOrganization.id === organization.id)
                if (existingOrganizationPosition > -1) {
                    state.organizationList[existingOrganizationPosition] = organization
                    return state.organizationList[existingOrganizationPosition]
                } else {
                    return state.organizationList.push(organization)
                }

            })
            state.organizationList = [...state.organizationList]
        },
        setSelectedOrganization: (state, { payload }) => {
            state.selectedOrganization = payload
        },
        deleteOrganizationFromList: (state, { payload }) => {
            state.organizationList = state.organizationList.filter(organization => organization.id !== payload)
        }

    },
});

export const {
    setOrganization,
    updateOrganizationDetail,
    setOrganizationList,
    setSelectedOrganization,
    deleteOrganizationFromList
} = organizationSlice.actions;
export default organizationSlice.reducer;
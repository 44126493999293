import { Stack, Group, Title, TextInput, Select, Text } from "@mantine/core"
import ProfileImageUploader from "../../BaseComponents/ProfileImageUploader"
import { ORGANIZATION_SIZE_CHOICES, ORGANIZATIONS_EMPLOYEES_AT_SITE_SIZE_CHOICES } from "../../../choices/organizationChoices"
import { useState, useEffect } from "react"
import { IconChevronDown } from "@tabler/icons-react"

const OrganizationRegistrationForm = ({ form, title = "Enter Your Organization Info", companyLogoUrl }) => {
    const selectDropDownIcon = <IconChevronDown size={24} color="#909296" />

    return (
        <Stack align="flex-start" justify="flex-start" gap={40} style={{ height: '100%' }}>
            <Stack gap={12}>
                <Title order={3}>{title}</Title>
                <Text span c="dimmed" fz={16}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
            </Stack>
            <Stack gap={16}>
                <Title order={5}>Upload logo company</Title>
                <ProfileImageUploader isCompany={true} profileImageURL={companyLogoUrl} updateImage={(image) => { form.setFieldValue('logo', image) }} deleteImage={() => { form.setFieldValue('logo', '') }} />
                <Group grow>
                    <TextInput
                        label={'Company name'}
                        {...form.getInputProps('name')}
                        error={form.errors.name}
                        required
                        style={{ position: 'relative' }}
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px",
                                backgroundColor: 'transparent'
                            },
                            error: {
                                fontSize: '14px',
                                marginTop: "5px",
                                lineHeight: "16px",
                                position: 'absolute',
                                bottom: '-14px'
                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                    />
                    <TextInput
                        label='Site name'
                        required
                        {...form.getInputProps('site_name')}
                        style={{ position: 'relative' }}
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px",
                                backgroundColor: 'transparent'
                            },
                            error: {
                                fontSize: '14px',
                                marginTop: "5px",
                                lineHeight: "16px",
                                position: 'absolute',
                                bottom: '-14px'
                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                    />
                </Group>
                <Select
                    label="Company size"
                    required
                    data={ORGANIZATION_SIZE_CHOICES}
                    rightSection={selectDropDownIcon}
                    {...form.getInputProps('size')}
                    style={{ position: 'relative' }}
                    styles={{
                        label: {
                            fontSize: '16px',
                            marginBottom: "10px",
                            lineHeight: "18px",
                            fontWeight: "600",
                            color: "#424242"
                        },
                        input: {
                            height: "40px",
                            fontSize: "14px",
                            padding: "12px",
                            lineHeight: "16px",
                            borderRadius: "6px",
                            backgroundColor: 'transparent'
                        },
                        error: {
                            fontSize: '14px',
                            marginTop: "5px",
                            lineHeight: "16px",
                            position: 'absolute',
                            bottom: '-14px'
                        },
                        required: {
                            color: '#3354F4'
                        }
                    }}
                />
                <Select
                    label="Number of employees at a site (Approx)"
                    required
                    data={ORGANIZATIONS_EMPLOYEES_AT_SITE_SIZE_CHOICES}
                    rightSection={selectDropDownIcon}
                    {...form.getInputProps('employees_at_site')}
                    style={{ position: 'relative' }}
                    styles={{
                        label: {
                            fontSize: '16px',
                            marginBottom: "10px",
                            lineHeight: "18px",
                            fontWeight: "600",
                            color: "#424242"
                        },
                        input: {
                            height: "40px",
                            fontSize: "14px",
                            padding: "12px",
                            lineHeight: "16px",
                            borderRadius: "6px",
                            backgroundColor: 'transparent'
                        },
                        error: {
                            fontSize: '14px',
                            marginTop: "5px",
                            lineHeight: "16px",
                            position: 'absolute',
                            bottom: '-14px'
                        },
                        required: {
                            color: '#3354F4'
                        }
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default OrganizationRegistrationForm
import { Stack, Group, Title, Text, Button, TextInput, Modal, ActionIcon, Loader, useSafeMantineTheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import { yupResolver } from "mantine-form-yup-resolver";
import * as yup from 'yup';
import { addAdminToOrganizations } from "../../../../../middlewares/partner/partners.services"
import { notifications } from "@mantine/notifications";
import { useDispatch } from "react-redux";
import UploadExcelFileModal from "../../../../Modal/UploadExcelModal/UploadExcelFileModal"
import { read, utils } from 'xlsx'
import { IconX, IconPlus } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";

import './AddCompanyModal.css'

const AddCompanyModal = ({ modalOpened = false, modalClose, title = "Upload the file and invite the company" }) => {

    const dispatch = useDispatch()
    const [opened, { open, close }] = useDisclosure()
    const [loading, setLoading] = useState()
    const validateOrganizations = yup.object().shape({
        organizations: yup.array().of(
            yup.object().shape({
                admin_email: yup.string().required('Email is required').email('Invalid email'),
                name: yup.string('Company name is required').required('Company name is required').matches(/^(?=.*[A-Za-z])(?!^[\d\s-]+$)(?!^[^\w\s-]+$)[A-Za-z\d\s-]+$/, 'Please enter valid name'),
                site_name: yup.string().required('Site url is required').matches(/^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/i, 'Please enter a valid url'),
                company_id: yup.string().required('Company id is required').matches(/^[a-zA-Z0-9_]+$/, 'Please enter a valid company id'),
                phone_number: yup.string().required('Phone number is required').matches(/^\+?[1-9]\d{0,2}(021|\d{1,4})\d{1,9}$/, 'Please enter a valid contact'),
            })
        )
    })

    const form = useForm({
        initialValues: {
            organizations: [
                { admin_email: '', name: '', site_name: '', company_id: '', phone_number: '' },
            ]
        },
        validate: yupResolver(validateOrganizations)
    })

    const textInputStyles = {
        label: {
            fontSize: '16px',
            marginBottom: "10px",
            marginTop: '20px',
            lineHeight: "18px",
            fontWeight: "600",
            color: "#424242"
        },
        input: {
            height: "40px",
            fontSize: "14px",
            padding: "12px",
            lineHeight: "16px",
            borderRadius: "6px"
        },
        error: {
            position: 'absolute',
        },
        required: {
            color: '#3354F4'
        }
    }

    const adminDetails = form.values.organizations.map((admin, index) => {
        return (
            <>
                <Group mb={20} key={`${index}_company`} grow gap={12} wrap="nowrap" align='flex-end' preventGrowOverflow={false}>
                    <TextInput
                        label="Email admin member"
                        placeholder="Enter admin email"
                        {...form.getInputProps(`organizations.${index}.admin_email`)}
                        key={`${index}_admin_email`}
                        withAsterisk
                        style={{ position: 'relative', minWidth: '20%' }}
                        styles={textInputStyles}
                    />
                    <TextInput
                        label="Company name"
                        placeholder="Enter company name"
                        {...form.getInputProps(`organizations.${index}.name`)}
                        key={`${index}_name`}
                        withAsterisk
                        style={{ position: 'relative', minWidth: '18%' }}
                        styles={textInputStyles}
                    />
                    <TextInput
                        label="Site name"
                        placeholder="Enter site url"
                        {...form.getInputProps(`organizations.${index}.site_name`)}
                        key={`${index}_site_name`}
                        withAsterisk
                        style={{ position: 'relative', minWidth: '20%' }}
                        styles={textInputStyles}
                    />
                    <TextInput
                        label="Phone Number"
                        placeholder="Enter contact"
                        {...form.getInputProps(`organizations.${index}.phone_number`)}
                        key={`${index}_phone_number`}
                        withAsterisk
                        style={{ position: 'relative', minWidth: '15.8%' }}
                        styles={textInputStyles}
                    />
                    <TextInput
                        label="Company ID"
                        {...form.getInputProps(`organizations.${index}.company_id`)}
                        placeholder="Enter company id"
                        key={`${index}_company_id`}
                        withAsterisk
                        style={{ position: 'relative', minWidth: '15.8%' }}
                        styles={textInputStyles}
                    />
                    <ActionIcon mb={6} variant="transparent" onClick={() => { form.removeListItem('organizations', index) }}>
                        <IconX size='sm' color={"#3354F4"} />
                    </ActionIcon>
                </Group>
            </>
        )
    })

    const addAnotherCompany = (organizationDetails = {}) => {
        if (organizationDetails && Object.keys(organizationDetails).length > 0) {
            organizationDetails.phone_number = String(organizationDetails.phone_number)
            form.insertListItem('organizations', organizationDetails);
        }
        else
            form.insertListItem('organizations', { admin_email: '', name: '', site_name: '', company_id: '', phone_number: '' });
    }

    const sendInvite = async () => {
        const { hasErrors } = await form.validate();
        if (hasErrors) {
            return
        }
        else if (form.values.organizations.length === 0) {
            notifications.show({ message: 'Please add a company', autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            return
        }
        else {
            setLoading(loading => !loading)
            const res = await dispatch(addAdminToOrganizations(form.values))
            if (addAdminToOrganizations.fulfilled.match(res)) {
                setLoading(loading => !loading)
                notifications.show({ message: 'You have registered organizations successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
                onModalClose()
            } else if (addAdminToOrganizations.rejected.match(res)) {
                if (Object.hasOwn(res.payload, 'response')) {
                    setLoading(loading => !loading)
                    if (res.payload.response.data.error.message === 'Integrity Error') {
                        const duplicateEmail = res.payload.response.data.error.details.split('The email ')[1].split(" ")[0]
                        if (duplicateEmail) {
                            const index = form.values.organizations.findIndex(organization => organization.admin_email === duplicateEmail)
                            form.setFieldError(`organizations.${index}.admin_email`, "This user already exists")
                        }
                    }
                    else {
                        notifications.show({ message: "Error occured while registering organizations", autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
                    }
                }
            }
        }
    }

    const uploadExcelFile = async (excelFile) => {
        try {
            const bufferedArray = await excelFile.arrayBuffer()

            const workbook = read(bufferedArray)
            const worksheet = workbook.Sheets[workbook.SheetNames[0]] // getting the first sheet
            const data = utils.sheet_to_json(worksheet)

            if (data.length > 0) {
                const updatedOrganizations = form.values.organizations.filter(organization =>
                    Object.values(organization).some(value => value)
                );
                form.setFieldValue('organizations', updatedOrganizations);
                data.forEach((row) => { addAnotherCompany(row) })
            }

        }
        catch (err) {
            console.log("THIS IS ERROR", err)
        }

        // closes upload execel file modal
        close()
    }

    const onModalClose = () => {
        form.reset()
        modalClose()
    }

    return (
        <>
            <Modal.Root opened={modalOpened} onClose={onModalClose} size={957} centered={true}>
                <Modal.Overlay />
                <Modal.Content px={24} py={24}>
                    <Modal.Header>
                        <Modal.Title style={{ width: '100%' }}>
                            <Group justify="space-between" align="flex-start">
                                <Stack gap={12}>
                                    <Title order={3} >Send and invite company</Title>
                                    <Text c='dimmed'>You can invite up to 5/10 companies</Text>
                                </Stack>
                                <Group gap={12} ml="auto">
                                    <Button key="upload_excel_button" variant="filled" bg="#099268" size="sm" onClick={open}>Upload (excel/sheets)</Button>
                                    <Button key="send_invite" variant="filled" bg="#3354F4" size="sm" radius={6} onClick={sendInvite}>
                                        <Group spacing="xs" align='flex-end'>
                                            <Text span> Send invite</Text>
                                            {loading && <Loader size="sm" color="#adb5c1" type='dots' />}
                                        </Group>
                                    </Button>
                                    <Button key="insert_line" variant="light" className='light-button' size="sm" radius={6} onClick={() => { addAnotherCompany() }}>
                                        <Group gap={8} justify="center" align="center">
                                            <IconPlus size={16} />
                                            <Text span>Add Line</Text>
                                        </Group>
                                    </Button>
                                </Group>
                            </Group>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body mt={24}>
                        {adminDetails}
                    </Modal.Body>
                </Modal.Content>
            </Modal.Root>
            <UploadExcelFileModal
                opened={opened}
                close={close}
                onDrop={uploadExcelFile}
            />
        </>
    )
}
export default AddCompanyModal

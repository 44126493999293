import TabBaseLayout from "../../components/Layout/TabBaseLayout/TabBaseLayout"
import CreateNotifications from "../../components/Notification/Partner/CreateNotifications/CreateNotifications"
const PartnerNotification = () => {

    const tabList = [
        {
            value: 'create_notifications', label: 'Create Notifications'
        },
        {
            value: 'schedule_notifications', label: 'Schedule Notifications'
        },
        {
            value: 'past_notifications', label: 'Past Notifications'
        }

    ]

    return (
        <TabBaseLayout tabList={tabList}>
            <CreateNotifications />
        </TabBaseLayout>
    )
}
export default PartnerNotification
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: null,
    success: null,
    loading: false,
};

const notificationSlice = createSlice({
    name: "Common",
    initialState,
    reducers: {
        setError: (state, { payload }) => {
            state.error = payload;
        },
        setSuccess: (state, { payload }) => {
            state.success = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
    },
});

export const { setError, setLoading, setSuccess } = notificationSlice.actions;
export default notificationSlice.reducer;
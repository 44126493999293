import React from 'react';
import classes from './safetyAwards.module.css';

const Card = ({ title, width, icon, type }) => {
    return (
        <>
            {type === "safetyProgram" ? (
                <div className={classes.card} style={{ marginBottom: width < 768 ? "15px" : 0, height:"" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={icon} alt="" />
                        <p className={classes.card_title} style={{ marginLeft: "12px" }}>{title}</p>
                    </div>
                    <p className={classes.card_title}>{title}</p>
                    <div>
                        <p className={classes.card_desc}>Start date: 28/10/2012</p>
                        <p className={classes.card_desc}>End date: 28/10/2013</p>
                    </div>
                </div>
            ) : (
                <div className={classes.card} style={{ marginBottom: width < 768 ? "15px" : 0 }}>
                    <p className={classes.card_title}>{title}</p>
                    <p className={classes.card_desc}>Lorem ipsum dolor sit amet, consectetur </p>
                </div>
            )}
        </>
    )
}

export default Card
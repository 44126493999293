import { Box, Divider, Group, Stack, Button, Loader, Grid } from "@mantine/core"
import { useForm } from "@mantine/form"
import OrganizationRegistrationForm from "../Form/OrganizationRegistration/OrganizationRegistrationForm"
import OrganizationBrandColorForm from "../Form/OrganizationRegistration/OrganizationBrandColorForm"
import { useEffect, useState } from "react"
import { useViewportSize } from '@mantine/hooks';
import { useSelector, useDispatch } from "react-redux"
import { updatePartnerOrganization } from "../../middlewares/partner/partners.services"
import { notifications } from "@mantine/notifications"



const PartnerCompanyProfile = () => {

    const [loading, setLoading] = useState(false)
    const { height, width } = useViewportSize();
    const dispatch = useDispatch()
    const [profileImageURL, setProfileImageURL] = useState(null)
    const organization = useSelector(state => state.organizations.organization)

    const form = useForm({
        initialValues: {
            name: '',
            size: '',
            site_name: '',
            employees_at_site: '',
            color: ''
        },
        validate: {
            name: (val) => (/^(?=.*[A-Za-z])(?!^[\d\s-]+$)(?!^[^\w\s-]+$)[A-Za-z\d\s-]+$/.test(val) ? null : 'Please enter a valid name'),
            size: (val) => (/\b[1234]\b$/.test(val) ? null : 'Company Size is required'),
            employees_at_site: (val) => (/\b[12345]\b$/.test(val) ? null : 'Number of employees is required'),
            site_name: (val) => (/^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/i.test(val) ? null : 'Please enter a valid site url'),
        }
    })

    useEffect(() => {
        form.setValues({
            name: organization?.name,
            size: organization?.size,
            site_name: organization?.site_name,
            employees_at_site: organization?.employees_at_site,
            color: organization?.color ? organization?.color : ''
        })

        setProfileImageURL(organization?.logo)
    }, [organization])

    useEffect(() => {
        setProfileImageURL(form.values?.logo ? URL.createObjectURL(form.values?.logo) : '')
    }, [form.values?.logo])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const { hasErrors } = await form.validate();
        if (!hasErrors && Object.hasOwn(organization, 'id')) {
            const formData = new FormData()
            Object.entries(form.values).forEach(([key, value]) => { formData.append(key, value) })
            const res = await dispatch(updatePartnerOrganization(formData))
            if (updatePartnerOrganization.fulfilled.match(res)) {
                notifications.show({ message: 'Organization details updated successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            }
            else if (updatePartnerOrganization.rejected.match(res)) {
                notifications.show({ message: 'Error occured while updating organization details', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            }
        }

    }

    return (
        <Box h={height - 60} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <form onSubmit={handleSubmit}>
                <Grid columns={24} h={{ md: 'fit-content', lg: height - 60, }}>
                    <Grid.Col span={{ xs: 24, sm: 24, md: 24, lg: 12, xl: 12 }} style={{ borderRight: width < 1200 ? 'none' : '1px solid rgb(222, 226, 230)' }} h={{ md: 'fit-content', lg: height - 52, }}>
                        <Box pl={19} pr={{ xs: 19, sm: 19, md: 19, xl: 82 }} pt={24}>
                            <OrganizationRegistrationForm
                                form={form}
                                title="Enter your company info"
                                companyLogoUrl={profileImageURL}
                            />
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 24, sm: 24, md: 24, lg: 12, xl: 12 }} >

                        <Box pr={{ xs: 19, sm: 19, md: 19, xl: 61 }} pl={19} py={24}>
                            <OrganizationBrandColorForm form={form} title='Company appearance' />
                            <Button type='submit' bg="#3354F4" w="130" h='40' ml="auto" display={"block"} mt="40" align="flex-end">
                                <Group>
                                    <span>Save Changes</span>
                                    {loading && <Loader size="sm" type='dots' color='#ffff' />}
                                </Group>
                            </Button>
                        </Box>

                    </Grid.Col>

                </Grid>
            </form>
        </Box >
    )
}

export default PartnerCompanyProfile
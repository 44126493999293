import { createAsyncThunk } from "@reduxjs/toolkit";
import { setOrganization, updateOrganizationDetail, setOrganizationList, deleteOrganizationFromList } from "../../store/organization/organizationSlice";
import { setCountries } from "../../store/common/commonSlice";
import apiClient from "../../services/api";

const apiFormInstance = apiClient(true)
const apiInstance = apiClient()
export const updateOrganization = createAsyncThunk("updateOrganization", async (data, { getState, dispatch, rejectWithValue }) => {
   try {
      const res = await apiFormInstance.put(`/api/organizations/${getState().organizations.organization.id}/update/`, data)
      dispatch(updateOrganizationDetail(res.data))
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})


export const createOrganization = createAsyncThunk("createOrganization", async (data, { getState, dispatch, rejectWithValue }) => {
   try {
      const res = await apiFormInstance.post('/api/organizations/create/', data)
      dispatch(setOrganization(res.data))
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})


export const fetchOrganizations = createAsyncThunk('fetchOrganization', async (_, { dispatch, rejectWithValue }) => {
   try {
      const res = await apiInstance.get('/api/organizations/')
      dispatch(setOrganization(res.data))
      return res

   }
   catch (err) {
      return rejectWithValue(err)
   }
})

export const fetchCountries = createAsyncThunk('fetchCountries', async (_, { dispatch, rejectWithValue }) => {
   try {
      const res = await apiInstance.get('/api/countries/')
      dispatch(setCountries(res.data))
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})

export const fetchOrganizationList = createAsyncThunk('fetchPartnerOrganization', async (_, { dispatch, rejectWithValue }) => {

   try {
      const res = await apiInstance.get('/api/organizations/list/')
      dispatch(setOrganizationList(res.data))
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }

})

export const bulkUpdateOrganizations = createAsyncThunk('updateOrganizationList', async (data, { dispatch, rejectWithValue }) => {

   try {
      const res = await apiInstance.put('/api/organizations/bulk/update/', data)
      dispatch(setOrganizationList(res.data.organizations))
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})

export const deleteOrganization = createAsyncThunk('deleteOrganization', async (organizationId, { getState, dispatch, rejectWithValue }) => {
   try {
      const res = await apiInstance.delete(`/api/organizations/${organizationId}/delete/`)
      if (getState().login.user.role === 'partner')
         dispatch(deleteOrganizationFromList(organizationId))
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})


export const sendOrganizationAdminInvite = createAsyncThunk('sendOrganizationAdminInvite', async (data, { getState, dispatch, rejectWithValue }) => {
   try {
      const res = await apiInstance.post(`/api/organizations/send-admin-email/`, data)
      // if (getState().login.user.role === 'partner')
      //    dispatch(deleteOrganizationFromList(organizationId))
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})
import { Card } from '@mantine/core';
import classes from '../Dashboard/card.module.css'

function DashboardCard({ icon, width, maxHeight, children }) {
    return (
        <Card padding="lg" radius="md" withBorder style={{ border: "1px solid #DEE2E6", minWidth: width, height:maxHeight, maxHeight:maxHeight}} className={classes.card}>
            {children}
            
            
            {/* <div style={{ display: "flex", alignItems: "center" }}>
                <img src={icon} style={{ width: "4.9rem" }} />
                <div style={{ marginLeft: 30 }}>
                    <Text fw={700} fz={32}>2,400 <sup style={{ fontSize: "16px", color: "#40C057", fontWeight: "600" }}>+5%</sup> </Text>
                    <Text fw={400} fz={12} c="#868E96">Total employees</Text>
                </div>
            </div> */}
        </Card>
    );
}

export default DashboardCard;
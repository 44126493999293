import { Box, Container, Stack, Image } from '@mantine/core'
import refraxtionLogo from "../../assets/images/partner-icon.png"

const BaseLayout = ({ children }) => {
    return (
        <>
            <Box>
                <Container fluid style={{ height: '100%' }} mt={24} ml={60}>
                    <Stack align="start" gap={37.95}>
                        <Image
                            w={224}
                            h={58.5}
                            fit='contain'
                            src={refraxtionLogo}
                        />
                        <Container size='lg'>
                            {children}
                        </Container>
                    </Stack>
                </Container>
            </Box>
        </>
    )
}

export default BaseLayout
import { Tabs, Indicator } from '@mantine/core';
import { LuUser } from "react-icons/lu";
import { LiaNewspaperSolid } from "react-icons/lia";
import { TbStereoGlasses } from "react-icons/tb";
import { FaShieldAlt } from "react-icons/fa";

function Tab() {
    return (
        <Tabs defaultValue="first">
            <Tabs.List>
                <Tabs.Tab className='employeeTab' fw="normal" fz={16} value={'1'}>
                    <LuUser size={20} />
                    <p>My Programs</p>
                </Tabs.Tab>
                <Tabs.Tab className='employeeTab' fw="normal" fz={16} value={'2'}>
                    <LiaNewspaperSolid size={20} />
                    <p>Requests</p>
                    {/* <Indicator inline processing color="#3354F4" size={12} style={{ position: "absolute", right: 3, top: 4, zIndex: 10000 }} /> */}
                </Tabs.Tab>
            </Tabs.List>
        </Tabs>
    );
}

export default Tab;
import { Stack, Group, Image, Text } from "@mantine/core"
import { IconChevronDown } from "@tabler/icons-react"

import { useSelector, useDispatch } from "react-redux"
import { useEffect, useMemo } from "react"

import { setSelectedOrganization } from "../../../store/organization/organizationSlice"

import './OrganizationListMenu.css'



const OrganizationListMenu = () => {

    const dispatch = useDispatch()
    const organizationList = useSelector(state => state.organizations.organizationList)
    const selectedOrganization = useSelector(state => state.organizations.selectedOrganization)

    useEffect(() => { console.log(selectedOrganization) }, [selectedOrganization])

    const selectOrganization = (organization) => {
        dispatch(setSelectedOrganization(organization))
    }

    const organizations = useMemo(() => {
        return organizationList.map(organization => {
            return (
                <Group key={organization.id} justify="space-between" className="menu-item" px={15} py={13}>
                    <Group gap={6} onClick={() => { selectOrganization(organization) }}>
                        {/* {organization?.logo ?
                        <Image src={organization?.logo} w={20} h={20} fit='contain' radius={6} />
                        :
                        <></>
                    } */}
                        <Image src={organization?.logo} w={20} h={20} fit='contain' radius={6} />

                        <Text span fw={700} fz={12}>{organization.name}</Text>
                    </Group>
                    <IconChevronDown size={16} color="white" />
                </Group>
            )
        })

    }, [organizationList])

    return (
        <>
            <Stack gap={0} className="organization-menu">
                {organizations}
            </Stack>
        </>
    )
}
export default OrganizationListMenu
import { Card, Image, Text, Badge, Button, Group } from '@mantine/core';
import classes from '../Dashboard/card.module.css'

function BillingCard({ icon, icon2, width, title, desc, sale }) {
    return (
        <Card padding="lg" radius="md" withBorder style={{ border: "1px solid #DEE2E6", width: "100%", position: "relative" }} className={classes.card}>
            <img src={icon2} style={{ width: "1rem", position: "absolute", right: 12, top: 12 }} />
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "48px",
                    height: "48px",
                    borderRadius: "6px",
                    backgroundColor: "#E9ECEF"
                }}>
                    <img src={icon} style={{ width: "1.5rem" }} />
                </div>
                <div style={{ marginLeft: 30 }}>
                    {title}
                    <Text fw={400} fz={12} c="#868E96">{desc}</Text>
                </div>
            </div>
        </Card>
    );
}

export default BillingCard;
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import commonReducer from "./common/commonSlice";
import authReducer from "./auth/authSlice";
import storage from 'redux-persist/lib/storage';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import employeesSlice from "./employess/employeesSlice";
import tierSlice from "./tiers/tierSlice";
import notificationSlice from "./notifications/notificationSlice";
import reportingSlice from "./reporting/reportingSlice";
import billingSlice from "./billing/billingSlice";
import safetyAwardsSlice from "./safetyAwards/safetyAwardsSlice";
import settingSlice from "./settings/settingSlice";
import organizationSlice from "./organization/organizationSlice";
import filterSlice from './filters/filterSlice'

const allreducers = combineReducers({
  common: commonReducer,
  login: authReducer,
  employee: employeesSlice,
  tiers: tierSlice,
  notifications: notificationSlice,
  reporting: reportingSlice,
  billing: billingSlice,
  safetyAwards: safetyAwardsSlice,
  settings: settingSlice,
  organizations: organizationSlice,
  filters: filterSlice
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ['login', 'user']
};

const persistedReducer = persistReducer(persistConfig, allreducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      serializableCheck: false
    }),
});

export const persistor = persistStore(store);
import { Box, Stack, Title, Divider, Group, Select, Textarea } from "@mantine/core"
import { useViewportSize } from "@mantine/hooks"
import { IconChevronDown } from "@tabler/icons-react"
import PrimaryButton from '../../../BaseComponents/Buttons/Button'

const CreateNotifications = () => {
    const { height } = useViewportSize()
    const iconChevronDown = <IconChevronDown />
    const styles = { label: { fontSize: '18px', marginBottom: '4px' } }
    return (
        <Box>
            <Group
                grow
                align="flex-start"
                h={height - 60}
                p={0}
            >
                <Stack p={24} gap={24}>
                    <Title order={3}> Create your email notification</Title>
                    <Stack gap={20}>
                        <Select label='Choose company' rightSection={iconChevronDown} size="md" styles={styles} />
                        <Select label='Recipients' rightSection={iconChevronDown} size="md" styles={styles} />
                        <Textarea label="Headline" placeholder="Notification Headline" size="md" styles={styles} />
                        <Textarea
                            radius={6}
                            label="Message (required)"
                            placeholder="Enter your message here"
                            size="md"
                            styles={{
                                ...styles,
                                input: {
                                    height: '93px'
                                }
                            }}
                        />
                    </Stack>
                    <Group ml='auto' gap={10}>
                        <PrimaryButton text="Send Push" />
                        <PrimaryButton text="Schedule your push" />
                    </Group>
                </Stack>
                <Divider orientation="vertical" />
            </Group>
        </Box>

    )
}

export default CreateNotifications
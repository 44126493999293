import React, { useEffect, useState } from 'react';
import { LuUser } from "react-icons/lu";
import { FaCheck } from "react-icons/fa6";
import { FiUserPlus } from "react-icons/fi";
import { RiBuilding4Line } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { IoRocketOutline } from "react-icons/io5";
import classes from '../Steps/Steps.module.css';

const Steps = ({ step, width }) => {

    const [steps, setSteps] = useState([
        { id: 1, Icon: LuUser, active: false, step: "Your details", desc: "Provide an email" },
        { id: 3, Icon: FiUserPlus, active: false, step: "Enter your personal info", desc: "Provide an email and password" },
        { id: 4, Icon: RiBuilding4Line, active: false, step: "Enter your company info", desc: "Start collaborating with your team" },
        { id: 5, Icon: FiUsers, active: false, step: "Invite your team", desc: "Start collaborating with your team" },
        { id: 6, Icon: IoRocketOutline, active: false, step: "Welcome to RFXN!", desc: "Start collaborating with your team" },
    ]);

    useEffect(() => {
        setSteps((prevSteps) => {
            return prevSteps.map((stp) => {
                if (stp.id <= step) {
                    return {
                        ...stp,
                        active: true
                    }
                }
                else {
                    return {
                        ...stp,
                        active: false
                    }
                }
            })
        });
    }, [step]);

    console.log({ steps });

    return (
        <div style={{ display: width < 991 && "flex", width: width < 991 && "100%", overflowX: width < 991 && "auto", margin: width < 991 ? "0 0 50px" : "0 0 40px", marginBottom:'40px !important', }}>
            {steps.map((stp, index) => {
                const { id, Icon, active, step, desc } = stp;
                return (
                    <div className={classes.stepContainer} style={{ width: width < 991 ? "max-content" : "", marginRight: width < 991 && "50px" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ backgroundColor: active === true && "#3354F4" }} className={classes.step} key={id}>
                                <Icon color={active === true ? 'white' : '#495057'} size={20} />
                            </div>
                            {width > 991 && index !== steps.length - 1 && <div style={{ backgroundColor: active === true ? "#3354F4" : "#F8F9FA", width: "3px", height: "20px", margin: "15px 0" }}></div>}
                        </div>
                        <div className={classes.stepTitleDesc}>
                            <p className={classes.title} style={{ width: width < 991 ? "max-content" : "" }}>{step}</p>
                            <p className={classes.desc} style={{ width: width < 991 ? "max-content" : "" }}>{desc}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default Steps;

import React from 'react'
import billingClass from './billing.module.css';
import classes from '../Button/button.module.css';
import { Box, Button, Input, Text } from '@mantine/core';

const TaxDetailForm = () => {
    return (
        <Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
                <Box className={billingClass.inputContainer}>
                    <Text className={billingClass.label} fw={600} c={'#424242'} mb={10}>Company Name</Text>
                    <Input value="Theresa Webb" radius={6} placeholder="Enter your company name" />
                </Box>
                <Box className={billingClass.inputContainer}>
                    <Text className={billingClass.label} fw={600} c={'#424242'} mb={10}>Phone Number</Text>
                    <Input value="Theresa Webb" radius={6} placeholder="Enter your phone number" />
                </Box>
            </Box>
            <Box className={billingClass.inputContainer}>
                <Text className={billingClass.label} fw={600} c={'#424242'} mb={10}>Recipient Email</Text>
                <Input value="Theresa Webb" radius={6} placeholder="Enter your email" />
            </Box>
            <Box className={billingClass.inputContainer}>
                <Text className={billingClass.label} fw={600} c={'#424242'} mb={10}>Address</Text>
                <Input value="Theresa Webb" radius={6} placeholder="Enter your address" />
            </Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
                <Box className={billingClass.inputContainer}>
                    <Text className={billingClass.label} fw={600} c={'#424242'} mb={10}>Zip / Postal code</Text>
                    <Input value="Theresa Webb" radius={6} placeholder="Enter your Zip/Postal code" />
                </Box>
                <Box className={billingClass.inputContainer}>
                    <Text className={billingClass.label} fw={600} c={'#424242'} mb={10}>Country</Text>
                    <Input value="Theresa Webb" radius={6} placeholder="Enter your country" />
                </Box>
            </Box>
            <Box className={billingClass.inputContainer}>
                <Text className={billingClass.label} fw={600} c={'#424242'} mb={10}>Phone Number</Text>
                <Input value="Theresa Webb" radius={6} placeholder="Enter your phone number" />
            </Box>
            <Box style={{ textAlign: "end" }}>
                <Button id={classes.contained} style={{ marginTop: "24px" }}>Update details</Button>
            </Box>
        </Box>
    )
}

export default TaxDetailForm;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    employees: [],
    totalPages: 0,
    totalNumberOfRecords: 0,
    perPageRecordsDisplayed: 0,
    currentPage: 1

};

const employeesSlice = createSlice({
    name: "employees",
    initialState,
    reducers: {
        setEmployees: (state, { payload }) => {
            state.employees = payload
        },
        updateEmployee: (state, { payload }) => {
            state.employees = state.employees.map((employee) => {
                if (employee.id === payload.id) {
                    return {
                        ...payload
                    }
                }
                else return employee
            })
        },
        addEmployee: (state, { payload }) => {
            if (Array.isArray(payload) && payload.length > 0) {
                console.log("IS ARRRAY")
                state.employees = [...state.employees, ...payload]
            }
            else {
                state.employees.push(payload)
            }
        },
        updateEmployeeStatusToInvited: (state, { payload }) => {
            state.employees = state.employees.map((employee) => {
                if (employee.id === payload.employee_id)
                    return {
                        ...employee,
                        status: '1'
                    }
                else
                    return employee
            })
        },
        sliceEmployee: (state, { payload }) => {
            state.employees = state.employees.filter(employee => employee.id !== payload)
        },
        updateEmployeePagination: (state, { payload }) => {
            state.totalPages = payload.total_pages
            state.totalNumberOfRecords = payload.total_records
        },
        updateCurrentPage: (state, { payload }) => { state.currentPage = payload }
    },
});

export const { setEmployees, updateEmployee, addEmployee, updateEmployeeStatusToInvited, sliceEmployee, updateEmployeePagination, updateCurrentPage } = employeesSlice.actions;
export default employeesSlice.reducer;
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Dashboard from './pages/dashboard/dashboard'
import Registration from './pages/login/Registration'
import Signup from './pages/signup/signup'
import RootLayout from './RootLayout'
import EmployeeManagement from './components/EmployeeManagement/EmployeeManagement'
import Notification from './pages/notification/notification'
import Billing from './pages/billing/billing'
import SafetyAwards from './pages/safety-awards/safetyAwards'
import Settings from './pages/settings/settings'
import TierManagement from './pages/tier-management/tierManagement'
import ReportingAndAnalytics from './pages/reporting-analytics/ReportingAndAnalytics'
import PatnerSignup from "./pages/signup/patnerSignup"
import PartnerDashboard from './pages/dashboard/PartnerDashboard'
import PartnerNotification from './pages/notification/PartnerNotification'
import PartnerSettings from './pages/settings/PartnerSettings'
import PartnerReportingAndAnalytics from './pages/reporting-analytics/PartnerReportingAndAnalytics'
import PartnerCompanyManagement from './pages/company-management/PartnerCompanyManagement'
import BaseLayout from './components/Layout/BaseLayout'
import Login from './pages/login/Login'
import { PrivateRoutes, PublicRoutes } from './router/RouterGuard'
const RouterApp = () => {

    return (
        <Router>
            <Routes>
                <Route element={<PublicRoutes allowedRoles={['admin']} />}>
                    <Route path='/' element={<Login />} />
                    <Route path='/admin/signup' element={<Registration />} />
                    <Route path='/signup' element={<Signup />} />
                </Route>

                <Route element={<PrivateRoutes allowedRoles={['admin']} />}>
                    <Route path='/admin' element={<BaseLayout />}>
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path='employee-management'>
                            <Route index element={<EmployeeManagement />} />
                            <Route path='edit/:id' element={<EmployeeManagement />} />
                        </Route>
                        <Route path='tier-management' element={<TierManagement />} />
                        <Route path='reporting-analytics' element={<ReportingAndAnalytics />} />
                        <Route path='notification' element={<Notification />} />
                        <Route path='billing' element={<Billing />} />
                        <Route path='safety-awards' element={<SafetyAwards />} />
                        <Route path='settings' element={<Settings />} />
                    </Route>
                </Route>

                <Route element={<PublicRoutes allowedRoles={['partner']}  />}>
                    <Route path='/' element={<Login />} />
                    <Route path='/partner/signup' >
                        <Route index element={<PatnerSignup />} />
                    </Route>
                </Route>

                <Route element={<PrivateRoutes allowedRoles={['partner']} />}>
                    <Route path='/partner' element={<BaseLayout />}>
                        <Route path="dashboard" element={<PartnerDashboard />} />
                        <Route path='company-management' element={<PartnerCompanyManagement />} />
                        <Route path='notifications' element={<PartnerNotification />} />
                        <Route path='reporting-and-analytics' element={<PartnerReportingAndAnalytics />} />
                        <Route path='settings' element={<PartnerSettings />} />
                    </Route>
                </Route>
            </Routes>

        </Router>
    )
}

export default RouterApp;
import React from 'react';
import classes from './billing.module.css';

const PaymentCard = ({ title, icon, selectPaymentMethod, setSelectPaymentMethod }) => {
    return (
        <div className={classes.paymentMethodCard} style={{ border: title === selectPaymentMethod ? "2px solid #1C7ED6" : "2px solid #E3E3E3" }} onClick={() => setSelectPaymentMethod(title)}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{
                    width: "70px",
                    height: "48px",
                    border: "2px solid #D9D9D9",
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <img src={icon} alt="" />
                </div>
                <div style={{ display: "flex" }}>
                    <div className={classes.cardHolderNumber}>
                        <p className={classes.cardTitle}>Card Holder number</p>
                        <p className={classes.cardValue}>3234 **** **** 7628</p>
                    </div>
                    <div className={classes.cardHolderName}>
                        <p className={classes.cardTitle}>Card Holder name</p>
                        <p className={classes.cardValue}>Maya Singh</p>
                    </div>
                    <div className={classes.cardExpiryDate}>
                        <p className={classes.cardTitle}>Expiry date</p>
                        <p className={classes.cardValue}>08/24</p>
                    </div>
                </div>
            </div>
            <div className={classes.selectCard}>
                <input type="radio" checked={title === selectPaymentMethod} />
                <p style={{
                    color: "#A6A7AB",
                    fontSize: "14px",
                    fontWeight: "400"
                }}>Default method</p>
            </div>
        </div>
    )
}

export default PaymentCard;
import React, { useState } from 'react'
import classes from '../Button/button.module.css'
import billingClass from './billing.module.css';
import { Box, Button, Divider } from '@mantine/core';
import PaymentCard from './PaymentCard';

// icons

import visa from '../../assets/icons/visa.svg';
import payPal from '../../assets/icons/payPal.svg';
import mastercard from '../../assets/icons/mastercard.svg';
import TaxDetailForm from './TaxDetailForm';
import { useMediaQuery } from '@mantine/hooks';

const PaymentMethod = () => {

  const [selectPaymentMethod, setSelectPaymentMethod] = useState("");
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  return (
    <Box className={billingClass.paymentMethodContainer}>
      <Box style={{ width: "700px", padding: "24px 24px 24px 0" }}>
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <p className={billingClass.title} style={{ marginBottom: 0 }}>Payment Method</p>
          <Button id={classes.outline}>Add new</Button>
        </Box>
        <Box style={{ marginTop: "14px" }}>
          <PaymentCard title="visa" selectPaymentMethod={selectPaymentMethod} setSelectPaymentMethod={setSelectPaymentMethod} icon={visa} />
          <PaymentCard title="paypal" selectPaymentMethod={selectPaymentMethod} setSelectPaymentMethod={setSelectPaymentMethod} icon={payPal} />
          <PaymentCard title="mastercard" selectPaymentMethod={selectPaymentMethod} setSelectPaymentMethod={setSelectPaymentMethod} icon={mastercard} />
        </Box>
      </Box>
      <Divider orientation="vertical" h={isSmallScreen ? "93vh" : "100vh"} />
      <Box style={{ width: "calc(100% - 700px)", padding: "24px" }}>
        <p className={billingClass.title} style={{ marginBottom: "24px" }}>Tax details</p>
        <TaxDetailForm />
      </Box>
    </Box>
  )
}

export default PaymentMethod;
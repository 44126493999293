import { Box, Button, TextInput, Grid, PasswordInput, Stack, Select, Group, Loader, Text } from '@mantine/core'
import { useForm } from '@mantine/form';
import { IconEdit, IconEye, IconEyeOff, IconChevronDown } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrganizations, updateOrganization, fetchCountries } from '../../middlewares/organization/organization';
import ProfileImageUploader from '../BaseComponents/ProfileImageUploader';
import { notifications } from '@mantine/notifications';
import { ORGANIZATION_TYPE_CHOICES } from '../../choices/organizationChoices';

const InputBox = ({ rightIcon, leftIcon, value, label, placeHolder, isPassword, onChange, error }) => {

    return (
        <>
            {isPassword ?
                <PasswordInput
                    label={label}
                    placeholder={placeHolder}
                    visibilityToggleIcon={({ reveal }) =>
                        reveal ? (
                            <IconEyeOff style={{ width: '16px', height: '16px' }} />
                        ) : (
                            <IconEye style={{ width: '16px', height: '16px' }} />
                        )
                    }
                    radius="6px"
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    error={error}
                    styles={() => ({
                        input: {
                            marginTop: '10px',
                        },
                        label: {
                            font: 'Roboto',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#495057'

                        }
                    })
                    }
                />
                :
                <TextInput
                    label={label}
                    placeholder={placeHolder}
                    rightSection={rightIcon}
                    leftSection={leftIcon}
                    leftSectionWidth={32}
                    rightSectionWidth={32}
                    radius="6px"
                    onChange={(event) => onChange(event.target.value)}
                    value={value}
                    error={error}
                    styles={() => ({
                        input: {
                            marginTop: '10px',
                            height: '32px'
                        },
                        label: {
                            font: 'Roboto',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#495057'

                        }
                    })}
                />
            }
        </>

    )
}
const CompanyProfile = () => {

    // initializing icon
    const selectIcon = <IconChevronDown style={{ width: '16px', height: 'auto', marginRight: '2px' }} />
    const editIcon = <IconEdit style={{ width: '16px', height: '16px', cursor: 'pointer' }} />

    const organization = useSelector(state => state.organizations.organization)
    const countries = useSelector(state => state.common.countries)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [profileImageURL, setProfileImageURL] = useState(null)

    // initializing form
    const form = useForm({
        initialValues: {
            name: '',
            company_type: '',
            zip_code: '',
            city: '',
            address: '',
            country: ''
        },
        validate: {
            name: (val) => (/^\s*$/.test(val) ? 'Name can not be null' : null),
            company_type: (val) => (/^[1-5]$/.test(val) ? null : "Please select one of the company type"),
            country: (val) => (/^[A-Z]{2}$/.test(val) ? null : 'Please select a country')
        },
    });

    async function fetchOrganizationData() {
        await dispatch(fetchOrganizations())
    }


    useEffect(() => {
        if (!organization) {
            fetchOrganizationData()
        }
        dispatch(fetchCountries())
    }, [])

    // update image url and form initial values
    useEffect(() => {
        if (organization) {
            setProfileImageURL(organization?.logo);
            form.setValues({
                name: organization?.name,
                company_type: organization?.company_type,
                zip_code: organization.zip_code ? organization.zip_code : '',
                city: organization.city ? organization.city : '',
                address: organization.address ? organization.address : '',
                country: organization?.country
            })
        }
    }, [organization]);

    function updateFormValue(value, formField) {
        if (formField === 'logo') {
            setProfileImageURL(value ? URL.createObjectURL(value) : null)
            form.setFieldValue(formField, value)
        } else {
            form.setFieldValue(formField, value)
        }

    }

    async function handleSubmit(e) {
        e.preventDefault();
        const formValidation = form.validate()
        if (formValidation.errors.name || formValidation.errors.company_type || formValidation.errors.country) {
            form.validate()
        }
        else {
            const formData = new FormData()

            Object.entries(form.values).forEach(([key, value]) => {
                formData.append(key, value)
            })

            setLoading(loading => !loading)
            let res = await dispatch(updateOrganization(formData))

            // clearing errors
            form.clearErrors();
            setLoading(loading => !loading)

            if (updateOrganization.fulfilled.match(res)) {
                notifications.show({ message: 'Company details have been updated', autoClose: 5000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" } });
            }
            else if (updateOrganization.rejected.match(res)) {
                notifications.show({ message: 'An error occured while updating company details', autoClose: 5000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" } });
            }

        }
    }

    return (
        <Box py={24}>
            <Text fw="bold" mb={16}>Company logo</Text>
            <form onSubmit={handleSubmit}>
                <Stack>
                    <ProfileImageUploader profileImageURL={profileImageURL} updateImage={(image) => { updateFormValue(image, 'logo') }} deleteImage={() => { updateFormValue('', 'logo') }} />
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <InputBox rightIcon={editIcon} label="Company name" value={form.values.name} onChange={(event) => updateFormValue(event, 'name')} error={form.errors.name} placeHolder="Enter company name" />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <Select
                                data={ORGANIZATION_TYPE_CHOICES}
                                label="Company type"
                                onChange={(_value, option) => form.setFieldValue('company_type', _value)}
                                value={form.values.company_type}
                                placeholder="Select company type"
                                rightSection={selectIcon}
                                error={form.errors.company_type}
                                styles={{
                                    label: {
                                        font: 'Roboto',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        color: '#495057'

                                    },
                                    input: {
                                        height: "32px",
                                        fontSize: "14px",
                                        padding: "12px",
                                        lineHeight: "16px",
                                        borderRadius: "6px",
                                        marginTop: '10px',
                                    },
                                    error: {
                                        fontSize: '14px',
                                        marginTop: "5px",
                                        lineHeight: "16px",
                                    },
                                    required: {
                                        color: '#3354F4'
                                    }
                                }}
                                defaultValue={null}
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <InputBox label="Address" value={form.values.address} onChange={(event) => updateFormValue(event, 'address')} placeHolder="Address" />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <Grid>
                                <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                                    <InputBox h={32} label="Zip / Postal code" value={form.values.zip_code} onChange={(event) => updateFormValue(event, 'zip_code')} placeHolder="Enter zip / postal code" />
                                </Grid.Col>
                                <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                                    <InputBox h={32} label="City / District" value={form.values.city} onChange={(event) => updateFormValue(event, 'city')} placeHolder="Enter city / district" />
                                </Grid.Col>
                            </Grid>
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <Select
                                data={countries}
                                label="Country"
                                value={form.values.country}
                                onChange={(_value, option) => form.setFieldValue('country', _value)}
                                placeholder="Please select a country"
                                rightSection={selectIcon}
                                error={form.errors.country}
                                styles={{
                                    label: {
                                        font: 'Roboto',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        color: '#495057'

                                    },
                                    input: {
                                        height: "32px",
                                        fontSize: "14px",
                                        padding: "12px",
                                        lineHeight: "16px",
                                        borderRadius: "6px",
                                        marginTop: '10px',
                                    },
                                    error: {
                                        fontSize: '14px',
                                        marginTop: "5px",
                                        lineHeight: "16px",
                                    },
                                    required: {
                                        color: '#3354F4'
                                    }
                                }}
                                defaultValue={null}
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid gutter={51}>
                        <Grid.Col span={6} >
                            <Group align='flex-end'>
                                <Button disabled={loading} ml="auto" type='submit' color="#3354F4" size="md" radius="6px" >
                                    <Group spacing='xs'>
                                        <span>Update Details</span>
                                        {loading && <Loader size="sm" color="#adb5c1" type='dots' />}
                                    </Group>
                                </Button>
                            </Group>
                        </Grid.Col>
                    </Grid>
                </Stack>
            </form>
        </Box>
    )
}
export default CompanyProfile
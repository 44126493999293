import { Modal, Group, Stack, Text, Card, Image, Button, ActionIcon } from "@mantine/core";
import { Dropzone, MS_EXCEL_MIME_TYPE } from "@mantine/dropzone";
import { IconTrash } from "@tabler/icons-react";
import uploadDocumentIcon from "../../../assets/icons/upload-document.svg"
import excelLogo from "../../../assets/icons/excel-logo.svg"
import "./UploadExcelFileModal.css"
import { useState, useEffect } from "react";




const UploadExcelFileModal = ({ opened, close, onDrop, title = "Upload the file to invite the company" }) => {

    const [excelFile, setExcelFile] = useState(null)

    useEffect(() => {
        setExcelFile(null)
    }, [])

    function formatFileSize(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

        return parseFloat((bytes / Math.pow(1024, i)).toFixed(decimals)) + ' ' + sizes[i];
    }

    function deleteExcelFile() {
        setExcelFile(null)
    }

    function closeModal() {
        setExcelFile(null)
        close()
    }

    return (
        <Modal
            opened={opened}
            onClose={closeModal}
            title={title}
            centered
            size='auto'
            closeOnClickOutside={false}
        >
            <Stack gap={24}>
                <Stack gap={12} >
                    <Dropzone
                        onDrop={(files) => { setExcelFile(files[0]) }}
                        maxSize={80 * 1024 ** 2}
                        className="uploadFileSection"
                        accept={MS_EXCEL_MIME_TYPE}
                        px={90}
                        py={24.5}
                        w={408}
                    >
                        <Stack justify="center" align="center" gap={12}>
                            <Dropzone.Idle>
                                <Image src={uploadDocumentIcon} fit='contain' w={74} h={78}></Image>
                            </Dropzone.Idle>
                            <Stack w={228}>
                                <Text span fz={14} fw={400} >
                                    <Text span fz={14} fw={600}>Drag and drop</Text> file here <Text span fz={14} fw={600}>or click here</Text> and select a file from your computer
                                </Text>
                            </Stack>
                            <Text span fz={12} fw={400} c='#696969' >
                                Max size - 80MB <span style={{ display: "inline-block", margin: "4px 5px", width: "4px", height: "4px", borderRadius: "100%", backgroundColor: "#5C5F66" }} /> Format: xlsx
                            </Text>
                        </Stack>
                    </Dropzone>
                    {excelFile ?
                        <Card withBorder={true} radius={8}>
                            <Stack>
                                <Group justify="space-around" align="flex-start">
                                    <Group gap={6}>
                                        <Image src={excelLogo} w={36} h={36} />
                                        <Stack gap={4}>
                                            <Text span fz={10} fw={500}>{excelFile.name}</Text>
                                            <Text fz={8} fw={400} c="#696969">{formatFileSize(excelFile.size)}</Text>
                                        </Stack >
                                    </Group>
                                    <ActionIcon ml="auto" bg="#F1F3F5" c="black" onClick={deleteExcelFile}>
                                        <IconTrash size={14} color="#141517" />
                                    </ActionIcon>
                                </Group>
                            </Stack>
                        </Card>
                        :
                        <></>
                    }

                </Stack>

                <Button disabled={!excelFile} ml='auto' bg="#3354F4" c="white" onClick={() => { onDrop(excelFile) }} >Add Company</Button>
            </Stack>
        </Modal>
    )
}

export default UploadExcelFileModal
import { Menu, Button, Stack, Group, Text } from '@mantine/core';
import { IconBell } from '@tabler/icons-react';
import NotificationComp from '../Notification/Notification';
import { FiFilter } from 'react-icons/fi';
import AccordionComp from './Accordion';
import { useSelector } from 'react-redux';

function Profile() {
    return <div style={{
        width: "32px",
        height: "32px",
        borderRadius: "6px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #FF922B",
        cursor: "pointer"
    }}>
        <IconBell color="#FF922B" size={20} />
    </div>
}

function Dropdown() {
    return (
        <Menu width={200}>
            <Menu.Target>
                <Button bg="none" px={0}>
                    <Profile />
                </Button>
            </Menu.Target>
            <Menu.Dropdown style={{ width: "max-content", border: "2px solid #FF922B", borderRadius: "8px", right: 35, left: "initial", padding: "10px" }}>
                {[...new Array(2)].map(() => {
                    return (
                        <Menu.Item style={{ margin: 0, padding: 0 }}>
                            <NotificationComp />
                        </Menu.Item>
                    )
                })}
            </Menu.Dropdown>
        </Menu>
    );
}

function FilterDropdown({ applyFilter, clearFilters }) {

    const employeeFilters = useSelector(state => state.filters.employeeFilters)
    let appliedEmployeeFilters = []
    const createEmployeeFilter = (appliedFilters) => {
        appliedEmployeeFilters = appliedFilters
    }

    const clearEmployeeFilter = () => {
        clearFilters({
            tier: [],
            rx_non_rx: [],
            status: []
        })
    }

    return (
        <Menu position="bottom-end">
            <Menu.Target>
                <Button style={{
                    backgroundColor: '#E9ECEF',
                    border: "2px solid #ADB5BD",
                    borderRadius: "6px",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#343A40",
                }}>
                    <FiFilter style={{ marginRight: 10, color: "#ADB5BD" }} /> Filter
                </Button>
            </Menu.Target>
            <Menu.Dropdown style={{ width: "321px", border: "2px solid #ADB5BD", borderRadius: "8px", padding: "16px" }}>
                <Stack>
                    <AccordionComp createEmployeeFilter={createEmployeeFilter} employeeFilterState={employeeFilters} />
                    <Group justify='space-between'>
                        <Text onClick={clearEmployeeFilter} style={{ cursor: 'pointer' }}>Clear all</Text>
                        <Button color="#3354F4" onClick={() => { applyFilter(appliedEmployeeFilters) }}>Apply Filter</Button>
                    </Group>
                </Stack>
            </Menu.Dropdown>
        </Menu>
    );
}

export { Dropdown, FilterDropdown }
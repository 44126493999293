import { createAsyncThunk } from "@reduxjs/toolkit";
import { setUser, updateUserProfile } from "../../store/auth/authSlice";
import apiClient from "../../services/api"
const apiInstance = apiClient()
const apiFormInstance = apiClient(true)

export const asyncRegister = createAsyncThunk(
    "auth/register",
    async (data, { dispatch, rejectWithValue }) => {

        try {
            const res = await apiInstance.post('/api/auth/registration/', data)
            dispatch(setUser(res.data.user))
            localStorage.setItem('access_token', res.data.access)
            localStorage.setItem('referesh_token', res.data.refresh)
            return res
        }
        catch (err) {
            return rejectWithValue(err)
        }
    }
);

export const updateUserData = createAsyncThunk("updateUserData", async (data, { dispatch, rejectWithValue }) => {

    try {
        const res = await apiFormInstance.put(`/api/profiles/`, data)
        await dispatch(updateUserProfile(res.data))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const googleLogin = createAsyncThunk("googleLogin", async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/google/', data)
        dispatch(setUser(res.data.user))
        localStorage.setItem('access_token', res.data.access)
        localStorage.setItem('refresh_token', res.data.refresh)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const loginWithIdMe = createAsyncThunk("idMeLogin", async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/id-me/', data)
        dispatch(setUser(res.data.user))
        localStorage.setItem('access_token', res.data.access)
        localStorage.setItem('refresh_token', res.data.refresh)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const loginWithFacebook = createAsyncThunk("loginWithFacebook", async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/facebook/', data)
        dispatch(setUser(res.data.user))
        localStorage.setItem('access_token', res.data.access)
        localStorage.setItem('refresh_token', res.data.refresh)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const fetchUser = createAsyncThunk("getUserDetails", async (_, { dispatch, rejectWithValue }) => {

    try {
        const res = await apiInstance.get('/api/auth/user/')
        dispatch(setUser(res.data))
    }
    catch (err) {
        rejectWithValue(err)
    }



})

export const changeUserPassword = createAsyncThunk("changeUserPassword", async (data, { rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/auth/password/change/', data)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const loginUser = createAsyncThunk("loginUser", async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/auth/login/', data)
        dispatch(setUser(res.data.user))
        localStorage.setItem('access_token', res.data.access)
        localStorage.setItem('referesh_token', res.data.refresh)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const resetPassword = createAsyncThunk("resetPassword", async (data, { rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/auth/password/reset/', data)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})
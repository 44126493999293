import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/api";
import { setUser } from "../../store/auth/authSlice";
import { setOrganization, setOrganizationList } from "../../store/organization/organizationSlice"

const apiInstance = apiClient()
const apiFormInstance = apiClient(true)

export const registerPartner = createAsyncThunk('registerPartner', async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/auth/registration/partner/', data)
        dispatch(setUser(res.data.user))
        localStorage.setItem('access_token', res.data.access)
        localStorage.setItem('refresh_token', res.data.refresh)
        return res
    } catch (err) {
        return rejectWithValue(err)
    }

})

export const registerParterViaGoogleAuth = createAsyncThunk('registerPartnerViaGoogleLogin', async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/google/partner', data)
        dispatch(setUser(res.data.user))
        localStorage.setItem('access_token', res.data.access)
        localStorage.setItem('refresh_token', res.data.refresh)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const registerPartnerOrganization = createAsyncThunk('registerPartnerOrganization', async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiFormInstance.post('/api/partner-organization/create/', data)
        dispatch(setOrganization(res.data))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const updatePartnerOrganization = createAsyncThunk('updatePartnerOrganization', async (data, { getState, dispatch, rejectWithValue }) => {
    try {
        const res = await apiFormInstance.put(`/api/partner-organization/${getState().organizations.organization?.id}/update/`, data)
        dispatch(setOrganization(res.data))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const addAdminToOrganizations = createAsyncThunk('addAdminToOrganization', async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/organizations/bulk/create/', data)
        dispatch(setOrganizationList(res.data.organizations))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const fetchPartnerOrganization = createAsyncThunk('fetchPartnerOrganization', async (_, { dispatch, rejectWithValue }) => {

    try {
        const res = await apiInstance.get('/api/partner-organization/list/')
        dispatch(setOrganization(res.data))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }

})



import React, { useState } from 'react'
import { Image, Box, Stack, TextInput, PasswordInput, Button, Divider, Group, Loader } from "@mantine/core";
import partnerIcon from "../../assets/images/partner-icon.png"
import { IoEyeOutline, IoEyeOffOutline, } from "react-icons/io5";
import { GoogleButton } from '../Button/Button.js';
import { useForm } from '@mantine/form';
import { registerPartner, registerParterViaGoogleAuth } from '../../middlewares/partner/partners.services.js';
import { notifications } from '@mantine/notifications';
import { useDispatch } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';


const PartnerSignUpForm = ({setStepper}) => {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const form = useForm({
        initialValues: {
            email: '',
            password1: '',
            password2: ''
        },
        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            password1: (val) => (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_\-])[A-Za-z\d@$!%*?&_\-]{8,}$/.test(val) ? null : 'Password must be at least 8 characters long, contain at least one lowercase letter, one uppercase letter, one number, and one special character.'),
            password2: (val) => (val === form.values.password1 ? null : 'Passwords do not match')
        }
    })

    const handleSubmit = async (e) => {
        e.preventDefault()

        const partnerRegistrationForm = form.validate()
        if (partnerRegistrationForm.errors.email || partnerRegistrationForm.errors.password1 || partnerRegistrationForm.errors.password2) {
            form.validate()
        }
        else {
            setLoading(true)
            localStorage.setItem('inProcess', 'true')
            const res = await dispatch(registerPartner(form.values))
            if (registerPartner.fulfilled.match(res)) {
                setLoading(false)
                notifications.show({ message: 'You have been registered successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
                setStepper(step=>step=2)
            }
            else if (registerPartner.rejected(res)) {
                setLoading(false)
                localStorage.setItem('inProcess', 'false')
                const errorResponse = res?.payload?.response?.data
                let message = 'Error occurred registering user'
                if (errorResponse && errorResponse?.error?.details?.email) {
                    message = errorResponse?.error?.details?.email[0]
                } else if (errorResponse && errorResponse?.error?.details?.non_field_errors) {
                    message = errorResponse?.error?.details?.non_field_errors[0]
                }
                notifications.show({ message: message, autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            }
        }

    }

    const login = useGoogleLogin({
        onSuccess: async (googleRes) => {
            const res = await dispatch(registerParterViaGoogleAuth({ access_token: googleRes.access_token }))
            if (registerParterViaGoogleAuth.fulfilled.match(res)) {
                notifications.show({ message: 'You have been registered successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            }
        }
    })

    return (
        <Box style={{ height: "calc(100vh - 48px)", display: "flex", alignItems: 'center', justifyContent: 'center' }}>
            <form style={{ maxWidth: '408px', height: "auto", width: "100%" }} onSubmit={handleSubmit}>
                <Image src={partnerIcon} w={224} mb={40} />
                <Stack gap={20}>
                    <TextInput
                        label="Email"
                        placeholder="test.test@gmail.com"
                        onChange={(event) => { form.setFieldValue('email', event.currentTarget.value) }}
                        value={form.values.email}
                        error={form.errors.email}
                        required={true}
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px"
                            },
                            error: {
                                fontSize: '14px',
                                marginTop: "5px",
                                lineHeight: "16px",
                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                    />
                    < PasswordInput
                        label="Password"
                        placeholder="Set Password"
                        value={form.values.password1}
                        error={form.errors.password1}
                        onChange={(event) => { form.setFieldValue('password1', event.currentTarget.value) }}
                        required={true}
                        visibilityToggleIcon={({ reveal }) =>
                            reveal ? (
                                <IoEyeOffOutline style={{ width: 'var(--psi-icon-size)', height: 'var(--psi-icon-size)' }} />
                            ) : (
                                <IoEyeOutline style={{ width: 'var(--psi-icon-size)', height: 'var(--psi-icon-size)' }} />
                            )
                        }
                        radius="md"
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px"
                            },
                            error: {
                                fontSize: '14px',
                                marginTop: "5px",
                                lineHeight: "16px",
                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                    />

                    < PasswordInput
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        value={form.values.password2}
                        error={form.errors.password2}
                        onChange={(event) => { form.setFieldValue('password2', event.currentTarget.value) }}
                        required={true}
                        visibilityToggleIcon={({ reveal }) =>
                            reveal ? (
                                <IoEyeOffOutline style={{ width: 'var(--psi-icon-size)', height: 'var(--psi-icon-size)' }} />
                            ) : (
                                <IoEyeOutline style={{ width: 'var(--psi-icon-size)', height: 'var(--psi-icon-size)' }} />
                            )
                        }
                        radius="md"
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px"
                            },
                            error: {
                                fontSize: '14px',
                                marginTop: "5px",
                                lineHeight: "16px",
                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                    />
                </Stack>
                <Button disabled={loading} type="submit" fullWidth mt={40} bg={'#3354F4'} c={'#ffff'} h={48} size={'20px'}>
                    <Group spacing="xs" align='flex-end'>
                        <span>Continue</span>
                        {loading && <Loader size="sm" type='dots' color='#ffffff' />}
                    </Group>
                </Button>
                <Divider label="Or" labelPosition="center" my="lg" style={{ margin: "20px 0" }} />
                <Group grow mb="md" mt="md">
                    <GoogleButton style={{ border: "1px solid #CED4DA", borderRadius: "8px", boxShadow: "0 1px 1px 0 rgba(0,0,0,0.1)", fontSize: "16px", lineHeight: "20px", color: "#696969", fontWeight: "500", height: "48px" }} bg="white" onClick={login}>Continue with Google</GoogleButton>
                </Group>
            </form>
        </Box>

    )
}

export default PartnerSignUpForm 
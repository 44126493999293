import { Box, Container, Group, Image, Stack, Table, Text, Select, Flex, TextInput, Button, Title, Loader } from "@mantine/core"
import './PartnerOrganization.css'
import ProfileImageUploader from "../BaseComponents/ProfileImageUploader"
import { useForm } from "@mantine/form"
import { ORGANIZATIONS_EMPLOYEES_AT_SITE_SIZE_CHOICES, ORGANIZATION_SIZE_CHOICES } from "../../choices/organizationChoices"
import { useEffect, useState } from "react"
import { registerPartnerOrganization, updatePartnerOrganization } from "../../middlewares/partner/partners.services"
import { useDispatch, useSelector } from "react-redux"
import { notifications } from "@mantine/notifications"
import BaseLayout from "./BaseLayout"

const PartnerOrganizationSignUpForm = ({setStepper}) => {

    const dispatch = useDispatch()
    const organization = useSelector(state => state.organizations.organization)

    const colorArray = ['#FA5252', '#E64980', '#BE4BDB', '#7950F2', '#4C6EF5', '#3354F4', '#15AABF', '#12B886']
    const colorPallet = colorArray.map(color => {
        return <Box key={color} w={38} h={38} bg={color} className="color-box"
            onClick={() => { form.setFieldValue('color', color) }}
            style={{ cursor: 'pointer' }}
        />
    })

    const [profileImageURL, setProfileImageURL] = useState('')
    const [loading, setLoading] = useState(false)

    const form = useForm({
        initialValues: {
            name: '',
            size: '',
            site_name: '',
            employees_at_site: '',
            color: colorArray[0]
        },
        validate: {
            name: (val) => (/^(?=.*[A-Za-z])(?!^[\d\s-]+$)(?!^[^\w\s-]+$)[A-Za-z\d\s-]+$/.test(val) ? null : 'Please enter a valid name'),
            size: (val) => (/\b[1234]\b$/.test(val) ? null : 'Company Size is required'),
            employees_at_site: (val) => (/\b[12345]\b$/.test(val) ? null : 'Number of employees is required'),
            site_name: (val) => (/^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/i.test(val) ? null : 'Please enter a valid site url'),
        }
    })

    useEffect(() => {
        if (form.values.logo)
            setProfileImageURL(URL.createObjectURL(form.values.logo))
        else
            setProfileImageURL('')
    }, [form.values.logo])

    useEffect(() => {
        if (organization?.id) {
            form.setValues({
                name: organization?.name,
                size: organization?.size,
                site_name: organization?.site_name,
                employees_at_site: organization?.employees_at_site,
            })
            setProfileImageURL(organization?.logo)

        }
    }, [organization])

    const registerOrganization = async (formData) => {
        setLoading(true)
        const res = await dispatch(registerPartnerOrganization(formData))
        setLoading(false)
        if (registerPartnerOrganization.fulfilled.match(res)) {
            notifications.show({ message: 'You have registered organization successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            setStepper(step=>step=3)
        }
        else if (registerPartnerOrganization.rejected.match(res)) {
            notifications.show({ message: 'Error occured while registering organization', autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
        }
    }

    const updateOrganization = async (formData) => {
        setLoading(true)
        const res = await dispatch(updatePartnerOrganization(formData))
        setLoading(false)
        if (updatePartnerOrganization.fulfilled.match(res)) {
            notifications.show({ message: 'Organization details updated successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            setStepper(step=>step=3)
        }
        else if (updatePartnerOrganization.rejected.match(res)) {
            notifications.show({ message: 'Error occured while updating organization details', autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const organizationForm = form.validate()
        const { name, size, site_name, employees_at_site } = organizationForm.errors
        const hasOrganizationErrors = name || size || site_name || employees_at_site
        if (hasOrganizationErrors) {
            form.validate()
        }
        else {
            const formData = new FormData()
            Object.entries(form.values).forEach(([key, value]) => {
                formData.append(key, value)
            })
            if (organization?.id) {
                updateOrganization(formData)
            }
            else {
                registerOrganization(formData)
            }

        }
    }


    return (
        <BaseLayout>
            <form onSubmit={handleSubmit}>
                <Flex className="table">
                    <Box className='table-border-right' p={40}>
                        <Flex justify='center' align='center' style={{ height: '100%' }}>
                            <Stack align="flex-start" justify="flex-start" gap={40} style={{ height: '100%' }}>
                                <Stack gap={12}>
                                    <Title order={3}>Enter Your Organization Info</Title>
                                    <Text span c="dimmed" fz={16}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                                </Stack>
                                <Stack gap={16}>
                                    <Title order={5}>Upload logo company</Title>
                                    <ProfileImageUploader isCompany={true} profileImageURL={profileImageURL} updateImage={(image) => { form.setFieldValue('logo', image) }} deleteImage={() => { form.setFieldValue('logo', '') }} />
                                    <Group grow>
                                        <TextInput
                                            label={'Company name'}
                                            placeholder="Please enter company name"
                                            value={form.values.name}
                                            error={form.errors.name}
                                            onChange={(event) => { form.setFieldValue('name', event.currentTarget.value) }}
                                            required
                                            style={{ position: 'relative' }}
                                            styles={{
                                                label: {
                                                    fontSize: '16px',
                                                    marginBottom: "10px",
                                                    lineHeight: "18px",
                                                    fontWeight: "600",
                                                    color: "#424242"
                                                },
                                                input: {
                                                    height: "40px",
                                                    fontSize: "14px",
                                                    padding: "12px",
                                                    lineHeight: "16px",
                                                    borderRadius: "6px"
                                                },
                                                error: {
                                                    fontSize: '14px',
                                                    marginTop: "5px",
                                                    lineHeight: "16px",
                                                    position: 'absolute',
                                                    bottom: '-14px'
                                                },
                                                required: {
                                                    color: '#3354F4'
                                                }
                                            }}
                                        />
                                        <TextInput
                                            label='Site name'
                                            placeholder="Please enter site url"
                                            required
                                            value={form.values.site_name}
                                            error={form.errors.site_name}
                                            onChange={(event) => { form.setFieldValue('site_name', event.currentTarget.value) }}
                                            style={{ position: 'relative' }}
                                            styles={{
                                                label: {
                                                    fontSize: '16px',
                                                    marginBottom: "10px",
                                                    lineHeight: "18px",
                                                    fontWeight: "600",
                                                    color: "#424242"
                                                },
                                                input: {
                                                    height: "40px",
                                                    fontSize: "14px",
                                                    padding: "12px",
                                                    lineHeight: "16px",
                                                    borderRadius: "6px"
                                                },
                                                error: {
                                                    fontSize: '14px',
                                                    marginTop: "5px",
                                                    lineHeight: "16px",
                                                    position: 'absolute',
                                                    bottom: '-14px'
                                                },
                                                required: {
                                                    color: '#3354F4'
                                                }
                                            }}
                                        />
                                    </Group>
                                    <Select
                                        label="Company size"
                                        placeholder="Please select company size"
                                        required
                                        data={ORGANIZATION_SIZE_CHOICES}
                                        error={form.errors.size}
                                        onChange={(_value, option) => form.setFieldValue('size', _value)}
                                        style={{ position: 'relative' }}
                                        styles={{
                                            label: {
                                                fontSize: '16px',
                                                marginBottom: "10px",
                                                lineHeight: "18px",
                                                fontWeight: "600",
                                                color: "#424242"
                                            },
                                            error: {
                                                fontSize: '14px',
                                                marginTop: "5px",
                                                lineHeight: "16px",
                                                position: 'absolute',
                                                bottom: '-14px'
                                            },
                                            required: {
                                                color: '#3354F4'
                                            }
                                        }}
                                    />
                                    <Select
                                        label="Number of Employees at a site (Approx)"
                                        placeholder="Please select number of employees"
                                        required
                                        data={ORGANIZATIONS_EMPLOYEES_AT_SITE_SIZE_CHOICES}
                                        error={form.errors.employees_at_site}
                                        onChange={(_value, option) => form.setFieldValue('employees_at_site', _value)}
                                        style={{ position: 'relative' }}
                                        styles={{
                                            label: {
                                                fontSize: '16px',
                                                marginBottom: "10px",
                                                lineHeight: "18px",
                                                fontWeight: "600",
                                                color: "#424242"
                                            },
                                            error: {
                                                fontSize: '14px',
                                                marginTop: "5px",
                                                lineHeight: "16px",
                                                position: 'absolute',
                                                bottom: '-14px'
                                            },
                                            required: {
                                                color: '#3354F4'
                                            }
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </Flex>
                    </Box>
                    <Box w={488} p={40}>
                        <Flex justify='center' align='center' style={{ height: '100%' }}>
                            <Stack align="flex-start" justify="flex-start" gap={40} style={{ height: '100%' }}>
                                <Stack gap={12}>
                                    <Title order={3}>Brand your Partner Account</Title>
                                    <Text span c="dimmed" fz={16}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                                </Stack>
                                <Stack>
                                    <Title order={5}>Choose your accent color</Title>
                                    <Group gap={10}>
                                        {colorPallet}
                                    </Group>
                                    <Group gap={10} justify="flex-start" align="center">
                                        <Text span>Custom color:</Text>
                                        <TextInput w={92} value={form.values.color} error={form.errors.color} onChange={(event) => { form.setFieldValue('color', event.currentTarget.value) }} />
                                        <Box w={38} h={38} bg={form.values.color} className="color-box"></Box>
                                    </Group>
                                </Stack>
                                <Button disabled={loading} type='submit' bg="#3354F4" size="md" ml="auto" mt="auto" align="flex-end">
                                    <Group>
                                        <span>Continue</span>
                                        {loading && <Loader size="sm" type='dots' color='#ffff' />}
                                    </Group>
                                </Button>
                            </Stack>
                        </Flex>
                    </Box>
                </Flex>
            </form>
        </BaseLayout>
    )
}
export default PartnerOrganizationSignUpForm
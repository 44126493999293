export const GENDER = {
    "Female": "F",
    "Male": "M",
    "Others": "O"
}

export const GENDER_CHOICES = [
    {value:'F', label:"Female"},
    {value:'M', label:"Male"},
    {value:'O', label:"Others"}
]
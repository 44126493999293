import { createAsyncThunk } from "@reduxjs/toolkit";
// import { callApi } from '../../services/api'
import apiClient from "../../services/api";
import { setEmployees, updateEmployee, addEmployee, updateEmployeeStatusToInvited, sliceEmployee, updateEmployeePagination } from "../../store/employess/employeesSlice";

const apiFormInstance = apiClient(true)
const apiInstance = apiClient()


export const uploadEmlpoyeeTemplate = createAsyncThunk("uploadEmployeeTemplate", async (data, { dispatch, rejectWithValue }) => {

    try {
        const res = await apiFormInstance.post('/api/uploadapi/', data)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }

})


export const addNewEmployee = createAsyncThunk('addNewEmployee', async (payload, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiFormInstance.post('/api/employees/create/', payload)
        dispatch(addEmployee(res.data))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const fetchAllEmployees = createAsyncThunk("fetchAllEmployees", async (_, { dispatch, getState, rejectWithValue }) => {
    try {
        let url = `/api/employees/filter/`

        // applying filters, search and pagination query
        const filters = getState().filters.employeeFilters
        const search = getState().filters.searchEmployeeByName
        const currentPage = getState().employee.currentPage
        const isFilterApplied = Object.values(filters).some((category) => category.length > 0)

        //applying filters
        if (isFilterApplied) {
            Object.entries(filters).forEach(([category, categoryValue], index) => {
                if (filters[category].length > 0) {
                    if (url.includes('?'))
                        url = url + `&${category}=${categoryValue.join(',')}`
                    else
                        url = url + `?${category}=${categoryValue.join(',')}`
                }
            })
        }

        //applying search
        if (search) {
            if (url.includes('?')) {
                url = url + `&name=${search}`
            } else {
                url = url + `?name=${search}`
            }
        }

        //applying pagination
        if (url.includes('?')) {
            url = url + `&page=${currentPage}`
        } else {
            url = url + `?page=${currentPage}`
        }

        const res = await apiInstance.get(url)
        dispatch(setEmployees(res.data.results))
        dispatch(updateEmployeePagination(res.data))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const editEmployee = createAsyncThunk('editEmployee', async (payload, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiFormInstance.put(`/api/employees/${payload.employeeId}/update/`, payload.data)
        dispatch(updateEmployee(res.data))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const inviteEmployee = createAsyncThunk('inviteEmployee', async (payload, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post(`/api/employees/invite/`, payload)
        dispatch(updateEmployeeStatusToInvited(payload))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const deleteAEmployee = createAsyncThunk('deleteEmployee', async (payload, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.delete(`/api/employees/${payload}/delete/`)
        dispatch(sliceEmployee(payload))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

// export const filterEmployees = createAsyncThunk('filterEmployees', async(payload, {dispa}))
import React, { useEffect, useState } from 'react'
import TableComp from '../Table/Table'
import { IoSearch } from 'react-icons/io5';
import { Button, Input, Text } from '@mantine/core';
import { FilterDropdown } from '../EmployeeManagement/Dropdown';
import classes from '../Button/button.module.css'
import billingClass from './billing.module.css';
import BillingCard from '../Billing/Card';

// Icons

import billingWallet from '../../assets/icons/billling-wallet.svg';
import billingTrend from '../../assets/icons/billing-trend.svg';
import billingNotes from '../../assets/icons/billing-notes.svg';
import awaitingSelection from '../../assets/icons/awaiting-selection.svg';
import billingSetting from '../../assets/icons/billing-setting.svg';

const OrderManagement = () => {

    const [openAddEmployeeDrawer, setOpenAddEmployeeDrawer] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    function handleResize() {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={billingClass.billingContainer}>
            <div style={{ marginBottom: "24px", display: "flex", justifyContent: width < 991 ? "center" : "space-between", flexDirection: width < 991 ? "column" : "row", alignItems: width < 991 ? "center" : "center", overflowX: width < 1414 ? "scroll" : "hidden", width: "100%" }}>
                <div style={{ width: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0, }}>
                    <BillingCard icon={billingWallet} icon2={billingSetting} width={width} title={<Text fw={700} fz={24}>$20.000</Text>} desc="Your budget limit" />
                </div>
                <div style={{ width: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : "22px", }}>
                    <BillingCard icon={billingTrend} width={width} title={<Text fw={700} fz={24}>$1.210</Text>} desc="Total spent" />
                </div>
                <div style={{ width: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : "22px", }}>
                    <BillingCard icon={billingNotes} width={width} title={<Text fw={700} fz={24}>12 <span style={{ fontSize: "16px" }}>request</span> </Text>} desc="Awaiting confirmation" />
                </div>
                <div style={{ width: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : "22px", }}>
                    <BillingCard sale={<sup style={{ fontSize: "16px", color: "#1C7ED6", fontWeight: "600" }}>-14%</sup>} icon={awaitingSelection} width={width} title={<Text fw={700} fz={24}>113 <span style={{ fontSize: "16px" }}>users</span> </Text>} desc="Awaiting selection" />
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "24px" }}>
                <div style={{ display: "flex", position: "relative" }}>
                    <IoSearch size={17} style={{ position: "absolute", top: "25%", zIndex: 1000, left: 10, color: "#ADB5BD" }} />
                    <Input placeholder="Search" className={classes.input} />
                    <FilterDropdown />
                    <Button id={classes.defaultDark} style={{ marginLeft: "10px" }}>Sort by</Button>
                </div>
                <div style={{ display: "flex" }}>
                    <Button id={classes.default}>Decline</Button>
                    <Button id={classes.contained} style={{ marginLeft: "10px" }}>Сonfirm order</Button>
                </div>
            </div>
            {/* <TableComp openAddEmployeeDrawer={openAddEmployeeDrawer} setOpenAddEmployeeDrawer={setOpenAddEmployeeDrawer} /> */}
        </div>
    )
}

export default OrderManagement;
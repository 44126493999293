import { useState } from 'react';
import { Table, Checkbox, Stack, Pagination, Group, Text, Box, Title } from '@mantine/core';
import classes from './Table.module.css'

function TableComp({ type, tableHeaders, data = [], isFilterApplied, fetchEmployeeLoader, setExportCSVData, totalRecords=0, totalPages=0, changePage }) {
  const [selectedRows, setSelectedRows] = useState([]);

  const rows = data.map(row => {
    return (
      <Table.Tr key={row.id} className={classes.hoverRow}>
        <Table.Td id={classes.tableValue}>
          <Checkbox aria-label="Select row" checked={row.checked} onChange={(event) => { setExportCSVData(row, event.currentTarget.checked) }} />
        </Table.Td>
        {tableHeaders.map(header => {
          return (
            <Table.Td id={classes.tableValue} style={{ textAlign: 'center' }}>
              <Group
                justify={header.value === 'frame_and_lens' ? 'center' : 'flex-start'}
              >
                <p className={header.value === 'actions' ? classes.tableAction : ''}>{row[header.value]}</p>
              </Group>
            </Table.Td>
          )
        })
        }
      </Table.Tr>
    )
  })

  const NoEmployeesFound = () => {
    return (
      <Box mx='auto' mt="152px">
        <Stack align='center' justify='center' gap={12}>
          <Title size={24}>
            {isFilterApplied ? 'No Search Result!' : 'You don\'t have any added employees yet'}
          </Title>
          <Box>
            {isFilterApplied ?
              <>
                <Text c="dark.3">Please try again</Text>
              </>
              :
              <>
                <Text c="dark.3" fz={16} fw={400} align="center">To get started, <Text span fw={600} style={{ color: '#3354F4' }}>upload a CSV file</Text> with employee</Text>
                <Text c="dark.3" fz={16} fw={400} align="center">information or add an employee <Text span fw={600} style={{ color: '#3354F4' }}>manually</Text></Text>
              </>
            }

          </Box>
        </Stack>
      </Box>
    )
  }

  return (
    <>
      <Stack
        justify='space-between'
        style={{ height: '100%' }}
      >
        <Box style={{
          overflow: 'auto',
          display: 'block',
          width: '100%',
          position: 'relative',
          height: 'calc(100vh - 250px)'
        }}>
          <Table withColumnBorders highlightOnHover style={{ position: 'absolute', top: '0', left: '0', border: '1px solid #ddd', borderRadius: '8px', }}>
            <Table.Thead className={classes.tableHead} style={{ position: 'sticky', top: '-1px' }}>
              <Table.Tr>
                <Table.Th id={classes.tableHeader}>
                  <Checkbox aria-label="Select row" checked={data.length > 0 && data.every(row=>row.checked)} onChange={(event)=>{setExportCSVData({},false,event.currentTarget.checked)}} />
                </Table.Th>
                {tableHeaders.map(header => {
                  return <Table.Th key={header.value} id={classes.tableHeader}>{header.label}</Table.Th>
                })}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data.length > 0 ? rows : null}
            </Table.Tbody>
          </Table>

          {data.length === 0 && !fetchEmployeeLoader ?
            <NoEmployeesFound />
            :
            <></>
          }
        </Box>
        <Group justify='space-between' align='center' my={24}>
          <Text c="dimmed">Showing {data.length}/{totalRecords} results</Text>
          <Pagination total={totalPages} withEdges onChange={changePage} mr="24px" />
        </Group>
      </Stack>

    </>
  );
}

export default TableComp;
import axios from "axios";


function apiClient(isForm=false){

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    headers: {
        'Content-Type': isForm ? "multipart/form-data" : "application/json"
    }
})

apiClient.interceptors.request.use(config=>{
    const accessToken = localStorage.getItem('access_token')
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
})


apiClient.interceptors.response.use(response=>response,error=>{
    // console.log(error)
    // if ( error.response.status === 401) {
    //     // add logic for refresh token 
    // }

    return Promise.reject(error)
})


return apiClient
}


export default apiClient 

import React, { useEffect, useState } from 'react';
import { IconBellRinging, IconReceipt2 } from '@tabler/icons-react';
import Card from '../../components/SafetyAwards/Card';
import { Button, Input, Text } from '@mantine/core';
import classes from '../../components/SafetyAwards/safetyAwards.module.css';
import Tab from '../../components/SafetyAwards/Tabs';
import ProgramInfo from '../../components/SafetyAwards/ProgramInfo';
import Rewards from '../../components/SafetyAwards/Rewards';
import Participants from '../../components/SafetyAwards/Participants';
import Requests from '../../components/SafetyAwards/Requests';
import ModalComp from '../../components/Modal/Modal';
import { IoSearch } from 'react-icons/io5';
import Employess from '../../components/SafetyAwards/Employess';
import clock from '../../assets/icons/clock.svg'

const SafetyAwards = () => {

    const data = [
        { label: 'Program info', icon: IconBellRinging },
        { label: 'Rewards', icon: IconReceipt2 },
        { label: 'Participants', icon: IconReceipt2 },
        { label: 'Requests', icon: IconReceipt2 },
    ];

    const [width, setWidth] = useState(window.innerWidth);
    const [notificationType, setNotificationType] = useState("Participants");
    const [openAddSafetyProgram, setOpenAddSafetyProgram] = useState(false);

    function handleResize() {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    console.log(width);

    return (
        <div style={{ overflow: 'hidden' }}>
            <ModalComp
                open={openAddSafetyProgram}
                setOpen={setOpenAddSafetyProgram}
                title={
                    <>
                        <p style={{
                            color: "#0D2029",
                            fontSize: "16px",
                            fontWeight: "700",
                            marginBottom: "8px"
                        }}>Add employee to safety program</p>
                        <p style={{
                            color: "#495057",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "17px"
                        }}>Select one or multiple employees to connect to the <span style={{ fontWeight: "600" }}>"Gold Safety Award"</span> program</p>
                    </>
                }
            >
                <div style={{ display: "flex" }}>
                    <div style={{ width: "100%", position: "relative" }}>
                        <IoSearch size={17} style={{ position: "absolute", top: "25%", zIndex: 1000, left: 10, color: "#ADB5BD" }} />
                        <Input width="100%" className={classes.input} placeholder='Search' />
                    </div>
                    <Button onClick={() => {
                        setOpenAddSafetyProgram(false)
                    }} style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        borderRadius: "8px",
                        width: "100px",
                        marginLeft: "8px"
                    }}>Add</Button>
                </div>
                <div style={{
                    border: "2px solid #F5F6F7",
                    padding: "10px",
                    borderRadius: "8px",
                    height: "100%",
                    width: "100%",
                    marginTop: "16px"
                }}>
                    {[...new Array(6)].map(() => <Employess />)}
                </div>
            </ModalComp>
            <div style={{ padding: "24px" }}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: width < 768 ? "column" : "row"
                }}>
                    <Card width={width} title="Program Overview" />
                    <Card width={width} title="Program Overview" />
                    <Card width={width} title="Program Overview" />
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "30px 0 20px 0" }}>
                    <p className={classes.title}>Safety programs</p>
                    <Button className={classes.createNewProgramBtn}>Create new program</Button>
                </div>
                <Tab />
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                    flexDirection: width < 768 ? "column" : "row"
                }}>
                    <Card type="safetyProgram" width={width} title="Gold Safety Award" icon={clock} />
                    <Card type="safetyProgram" width={width} title="Gold Safety Award" icon={clock} />
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                    flexDirection: width < 768 ? "column" : "row"
                }}>
                    <Card type="safetyProgram" width={width} title="Gold Safety Award" icon={clock} />
                    <Card type="safetyProgram" width={width} title="Gold Safety Award" icon={clock} />
                </div>
            </div>
            {/* <div style={{ display: "flex", flexDirection: width < 991 ? "column" : "row", width: "100%" }}>
                <div style={{ height: "100%", display: width < 991 && "none", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0 }}>
                    <NotificationSidebar data={data} setNotificationType={setNotificationType} />
                </div>
                {notificationType === "Program info" && (
                    <div style={{ width: "100%", height: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0, }}>
                        <ProgramInfo />
                    </div>
                )}
                {notificationType === "Rewards" && (
                    <div style={{ width: "100%", height: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0, }}>
                        <Rewards />
                    </div>
                )}
                {notificationType === "Participants" && (
                    <div style={{ width: "100%", height: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0, }}>
                        <Participants setOpenAddSafetyProgram={setOpenAddSafetyProgram} />
                    </div>
                )}
                {notificationType === "Requests" && (
                    <div style={{ width: "100%", height: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0, }}>
                        <Requests />
                    </div>
                )}
            </div> */}
        </div>
    );
};

export default SafetyAwards;
import { Box, Button, TextInput, Grid, PasswordInput, Stack, Group, Loader, Text } from '@mantine/core'
import { useForm } from '@mantine/form';
import { IconEdit, IconEye, IconAt, IconEyeOff } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, updateUserData, changeUserPassword } from "../../middlewares/auth/auth"
import ProfileImageUploader from '../BaseComponents/ProfileImageUploader';
import { notifications } from '@mantine/notifications';
import { useMediaQuery } from '@mantine/hooks';

const InputBox = ({ rightIcon, leftIcon, value, label, placeHolder, isPassword, onChange, error }) => {

    return (
        <>
            {isPassword ?
                <PasswordInput
                    label={label}
                    placeholder={placeHolder}
                    visibilityToggleIcon={({ reveal }) =>
                        reveal ? (
                            <IconEyeOff style={{ width: '16px', height: '16px' }} />
                        ) : (
                            <IconEye style={{ width: '16px', height: '16px' }} />
                        )
                    }
                    radius="6px"
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    error={error}
                    styles={() => ({
                        input: {
                            marginTop: '10px',
                        },
                        label: {
                            font: 'Roboto',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#495057'

                        }
                    })
                    }
                />
                :
                <TextInput
                    label={label}
                    placeholder={placeHolder}
                    rightSection={rightIcon}
                    leftSection={leftIcon}
                    radius="6px"
                    onChange={(event) => onChange(event.target.value)}
                    value={value}
                    error={error}
                    styles={() => ({
                        input: {
                            marginTop: '10px',
                        },
                        label: {
                            font: 'Roboto',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#495057'

                        }
                    })}
                />
            }
        </>

    )
}
const UserProfile = () => {

    // initializing icon
    const editIcon = <IconEdit style={{ width: '16px', height: '16px', cursor: 'pointer' }} />
    const atIcon = <IconAt style={{ width: '16px', height: '16px' }} />
    const isSmallScreen = useMediaQuery("(max-width: 768px)");

    const user = useSelector(state => state.login.user)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [profileImageURL, setProfileImageURL] = useState(null)

    // initializing form
    const form = useForm({
        initialValues: {
            name: '',
            email: '',
            address: '',
        },
        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            name: (val) => (val !== null ? null : 'Name can not be null')
        },
    });

    const passwordForm = useForm({
        initialValues: {
            password1: '',
            password2: ''
        },
        validate: {
            password1: (val) => (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_\-])[A-Za-z\d@$!%*?&_\-]{8,}$/.test(val) ? null : 'Password must be at least 8 characters long, contain at least one lowercase letter, one uppercase letter, one number, and one special character.'),
            password2: (val) => (val === passwordForm.values.password1 ? null : 'Passwords do not match'),
        }
    })



    async function fetchUserData() {
        await dispatch(fetchUser())
    }

    useEffect(() => {
        if (!user) {
            fetchUserData()
        }
    }, [])

    // update formField state and image url and form initial values
    useEffect(() => {
        if (user && user?.profile) {
            setProfileImageURL(user?.profile?.image);
            form.setValues({
                name: user.profile?.name || '',
                email: user?.email || '',
                address: user.profile?.address || '',
            })
        }
    }, [user]);

    function updateFormValue(value, formField) {
        if (formField === 'image') {
            setProfileImageURL(value ? URL.createObjectURL(value) : null)
            form.setFieldValue(formField, value)
        } else {
            formField === 'password1' || formField === 'password2' ? passwordForm.setFieldValue(formField, value) : form.setFieldValue(formField, value)
        }

    }

    async function handleSubmit(e) {
        e.preventDefault();

        if (passwordForm.values.password1 || passwordForm.values.password2) {
            if (passwordForm.validate().errors.password1 || passwordForm.validate().errors.password2) {
                passwordForm.validate()
            }
        }
        if (form.validate().errors.email || form.validate().errors.name) {
            form.validate()

        }
        else {
            const formUserData = new FormData()

            Object.entries(form.values).forEach(([key, value]) => {
                formUserData.append(key, value)
            })


            setLoading(loading => !loading)
            let res = await dispatch(updateUserData(formUserData))

            if (updateUserData.fulfilled.match(res)) {
                notifications.show({ message: 'User details have been updated', autoClose: 5000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
                form.clearErrors();
            }
            else if (updateUserData.rejected.match(res)) {
                notifications.show({ message: 'An error occured while updating user details', autoClose: 5000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            }

            if (passwordForm.values.password1 && passwordForm.values.password2) {
                const passwordPayload = {
                    new_password1: passwordForm.values.password1,
                    new_password2: passwordForm.values.password2,
                }
                let changePasswordResponse = await dispatch(changeUserPassword(passwordPayload))

                if (changeUserPassword.fulfilled.match(changePasswordResponse)) {
                    passwordForm.reset()
                    notifications.show({ message: 'Password has been changed', autoClose: 5000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" } });
                    passwordForm.clearErrors();
                } else if (changeUserPassword.rejected.match(changePasswordResponse)) {
                    notifications.show({ message: 'An error occured while updating user password', autoClose: 5000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" } });
                }
            }

            // falsefy the button loader
            setLoading(loading => !loading)

        }
    }

    return (
        <Box py={24} pr={!isSmallScreen && 58}>
            <Text fw="bold" mb={16}>Your profile picture</Text>
            <form onSubmit={handleSubmit}>
                <Stack>
                    <ProfileImageUploader profileImageURL={profileImageURL} updateImage={(image) => { updateFormValue(image, 'image') }} deleteImage={() => { updateFormValue('', 'image') }} />
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <InputBox rightIcon={editIcon} label="Name" value={form.values.name} onChange={(event) => updateFormValue(event, 'name')} error={form.errors.name} placeHolder="Please enter name" />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <InputBox isPassword={true} label="Password" value={passwordForm.values.password1} onChange={(event) => updateFormValue(event, 'password1')} error={passwordForm.errors.password1} placeHolder="Set new password" />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <InputBox rightIcon={editIcon} label="Email" value={form.values.email} onChange={(event) => updateFormValue(event, 'email')} error={form.errors.email} placeHolder="Please enter email" />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <InputBox isPassword={true} label="Conform Password" value={passwordForm.values.password2} onChange={(event) => updateFormValue(event, 'password2')} error={passwordForm.errors.password2} placeHolder="Confirm new password" />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <InputBox leftIcon={atIcon} label="Site/Location" value={form.values.address} onChange={(event) => updateFormValue(event, 'address')} error={form.errors.address} placeHolder="Please enter address" />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={12}>
                            <Group align='flex-end' mt="34px">
                                <Button disabled={loading} type='submit' size="md" ml="auto" color="#3354F4">
                                    <Group spacing="xs">
                                        <span>Update Details</span>
                                        {loading && <Loader size="sm" color="#adb5c1" type='dots' />}
                                    </Group>
                                </Button>
                            </Group>
                        </Grid.Col>
                    </Grid>
                </Stack>
            </form>
        </Box>
    )
}
export default UserProfile
import { Flex, Box, Text, Image, Group, ActionIcon, Burger } from "@mantine/core"
import { useLocation } from "react-router-dom"
import { Dropdown } from "../EmployeeManagement/Dropdown"
import { useSelector } from 'react-redux';
import { IconLogout } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { renderTabs } from "../../lib/routes";
const BaseHeader = ({opened, toggle}) => {

    const { pathname } = useLocation()
    const navigate = useNavigate()
    const user = useSelector((state) => state.login?.user)

    const headerTitle = () => {
        const activeTab = renderTabs(user?.role).find((tab) => tab.link === pathname)
        return activeTab.name
    }

    const logoutUser = () => {
        localStorage.clear()
        navigate('/')
    }

    return (
        <>
            <Flex align="center" h={60} justify='space-between'>
                <Burger
                    opened={opened}
                    onClick={toggle}
                    hiddenFrom="sm"
                    size="sm"
                />
                <Box><Text span c="#909296" fz={14}>{headerTitle()}</Text></Box>
                <Group gap={12} justify="flex-end" >
                    <Text style={{ color: "#343A40", fontWeight: "500" }}>{user?.profile?.name}</Text>
                    <Image src={user?.profile?.image} alt="" fit='contain' w={32} h={32} />
                    <Dropdown />
                    <ActionIcon variant="transparent" color="#3354F4" onClick={() => { logoutUser() }}>
                        <IconLogout style={{ width: '36px', height: '36px' }} />
                    </ActionIcon>
                </Group>
            </Flex>
        </>
    )
}
export default BaseHeader
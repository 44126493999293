import { Title, Text, Button, Group, Divider, Flex, Box, Stack, TextInput, Checkbox, Loader } from "@mantine/core"
import { useViewportSize } from '@mantine/hooks';
import { useForm } from "@mantine/form"
import { yupResolver } from "mantine-form-yup-resolver";
import * as yup from 'yup';
import { IconSearch, IconX } from "@tabler/icons-react"
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import { bulkUpdateOrganizations, deleteOrganization, sendOrganizationAdminInvite } from "../../../../middlewares/organization/organization";
import { EMPLOYEE_STATUS_BLOCK } from "../../../../choices/employee.choice";
import AddCompanyModal from "./Modal/AddCompanyModal";
import { useDisclosure } from "@mantine/hooks";

import "./Company.css"
const Company = () => {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState()
    const [deleteCompanyButtonLoading, setDeleteCompanyButtonLoading] = useState()
    const searchIcon = <IconSearch width={16} h={16} color="#ADB5BD" />
    const clearSearchIcon = <IconX width={16} h={16} color="#ADB5BD" />
    const textInputStyles = {
        label: {
            fontSize: '14px',
            marginBottom: "10px",
            marginTop: '20px',
            lineHeight: "18px",
            fontWeight: "600",
            color: "#424242"
        },
        input: {
            height: "28px",
            fontSize: "14px",
            padding: "12px",
            lineHeight: "16px",
            borderRadius: "6px",
            backgroundColor: 'transparent'
        },
        error: {
            fontSize: '14px',
            marginTop: "5px",
            lineHeight: "16px",
            position: 'absolute',
        },
        required: {
            color: '#3354F4'
        }
    }

    const validateOrganizations = yup.object().shape({
        organizations: yup.array().of(
            yup.object().shape({
                admin_email: yup.string().required('Email is required').email('Invalid email'),
                name: yup.string('Company name is required').required('Company name is required').matches(/^(?=.*[A-Za-z])(?!^[\d\s-]+$)(?!^[^\w\s-]+$)[A-Za-z\d\s-]+$/, 'Please enter valid name'),
                site_name: yup.string().required('Site url is required').matches(/^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/i, 'Please enter a valid url'),
                company_id: yup.string().required('Company id is required').matches(/^[a-zA-Z0-9_]+$/, 'Please enter a valid company id'),
                phone_number: yup.string().required('Phone number is required').matches(/^\+?[1-9]\d{0,2}(021|\d{1,4})\d{1,9}$/, 'Please enter a valid contact'),
            })
        )
    })
    const { width } = useViewportSize();
    const [opened, { open, close }] = useDisclosure()


    let organizations = useSelector(state => state.organizations.organizationList)
    const existingOrganizationListForm = useForm({
        initialValues: {
            organizations: [
                { id: '', admin_email: '', name: '', site_name: '', company_id: '', phone_number: '', selected: false },
            ]
        },
        validate: yupResolver(validateOrganizations)
    })

    useEffect(() => {
        existingOrganizationListForm.reset()
        existingOrganizationListForm.removeListItem('organizations', 0);
        organizations.forEach(organization => {
            existingOrganizationListForm.insertListItem('organizations', {
                id: organization?.id,
                admin_email: organization?.admin_profile?.email,
                name: organization?.name,
                site_name: organization?.site_name,
                company_id: organization?.company_id,
                phone_number: organization?.phone_number,
                status: organization?.admin_profile?.profile?.status,
                selected: false
            })
        })
    }, [organizations])


    const organizationListTemplate = existingOrganizationListForm.values.organizations.map((organization, index) => {
        return (
            <Group key={organization.id} mb={10} gap={24} className={organization.selected ? 'organization-row selected-organization' : 'organization-row'} >

                <Group align="center" px={24} py={9} wrap="nowrap" style={{ width: '100%', position: 'relative' }}>
                    <Box w={6} h={'100%'} style={{ position: 'absolute', left: '0' }} className={organization.selected ? 'selected-organization-rectangle' : ''} />
                    <Checkbox
                        color="#3354F4"
                        mt={30}
                        checked={organization?.selected}
                        onChange={() => { selectOrganization(organization.id) }}
                    />
                    <TextInput
                        key={`${organization.id}_admin_email`}
                        placeholder="Company admin email"
                        required
                        label="Email admin member"
                        style={{ position: 'relative', width: '241px' }}
                        styles={textInputStyles}
                        {...existingOrganizationListForm.getInputProps(`organizations.${index}.admin_email`)}
                    />
                    <TextInput
                        key={`${organization.id}_name`}
                        placeholder="Company name"
                        required
                        label="Company Name"
                        style={{ position: 'relative', width: '134px' }}
                        styles={textInputStyles}

                        {...existingOrganizationListForm.getInputProps(`organizations.${index}.name`)}
                    />
                    <TextInput
                        key={`${organization.id}_site_name`}
                        placeholder="Site name"
                        required
                        label="Site name"
                        style={{ position: 'relative', width: '185px' }}
                        styles={textInputStyles}

                        {...existingOrganizationListForm.getInputProps(`organizations.${index}.site_name`)}
                    />
                    <TextInput
                        key={`${organization.id}_company_id`}
                        placeholder="Company ID"
                        required
                        label="Company ID"
                        style={{ position: 'relative', width: '117px' }}
                        styles={textInputStyles}

                        {...existingOrganizationListForm.getInputProps(`organizations.${index}.company_id`)}
                    />
                    <TextInput
                        key={`${organization.id}_phone_number`}
                        placeholder="Phone number"
                        required
                        label="Phone number"
                        style={{ position: 'relative', width: '140px' }}
                        styles={textInputStyles}

                        {...existingOrganizationListForm.getInputProps(`organizations.${index}.phone_number`)}
                    />
                    <Stack align="flex-start" gap={10}>
                        <Text>Status</Text>
                        {organization?.status ? EMPLOYEE_STATUS_BLOCK[organization?.status] : null}
                    </Stack>

                </Group>
            </Group>
        )
    })

    const updateOrganizationList = async (e) => {
        e.preventDefault()
        const { hasErrors } = await existingOrganizationListForm.validate();
        if (hasErrors) {
            return
        }
        else {
            setLoading(loading => !loading)
            const list = existingOrganizationListForm.values.organizations.map(organization => { delete organization.selected; return organization })
            const res = await dispatch(bulkUpdateOrganizations({ 'organizations': list }))
            if (bulkUpdateOrganizations.fulfilled.match(res)) {
                setLoading(loading => !loading)
                window.toast("Organizations' details updated successfully")
            }
            else if (bulkUpdateOrganizations.rejected.match(res)) {
                setLoading(loading => !loading)
                if (Object.hasOwn(res.payload, 'response')) {
                    if (res.payload.response.data.error.message === 'Integrity Error') {
                        const duplicateEmail = res.payload.response.data.error.details.split('The email ')[1].split(" ")[0]
                        if (duplicateEmail) {
                            const index = existingOrganizationListForm.values.organizations.findIndex(organization => organization.admin_email === duplicateEmail)
                            existingOrganizationListForm.setFieldError(`organizations.${index}.admin_email`, "This user already exists")
                        }
                    }
                    else {
                        window.toast("Error occured while updating organization details", 'error')
                    }
                }
            }
        }
    }

    const deleteSelectedOrganization = async () => {
        const selectedOrganization = existingOrganizationListForm.values.organizations.find(organization => organization.selected)
        if (selectedOrganization) {
            setDeleteCompanyButtonLoading(loading => !loading)
            const res = await dispatch(deleteOrganization(selectedOrganization.id))
            if (deleteOrganization.fulfilled.match(res)) {
                setDeleteCompanyButtonLoading(loading => !loading)
                window.toast("Organization deleted successfully")
            } else if (deleteOrganization.rejected.match(res)) {
                setDeleteCompanyButtonLoading(loading => !loading)
                window.toast("Error occured while deleting organization", 'error')
            }
        }
    }

    const selectOrganization = async (id) => {
        existingOrganizationListForm.setFieldValue('organizations',
            existingOrganizationListForm.values.organizations.map(organization => (
                { ...organization, selected: organization.id === id ? !organization?.selected : false }
            ))

        )
    }

    const isOrganizationSelected = () => {
        return existingOrganizationListForm.values.organizations.some(organization => organization.selected)
    }

    const openAddCompanyModal = () => {
        // open is a function inherited from useDisclosure hook from mantine
        open()
    }

    const resendInvitation = async () => {
        const selectedOrganization = existingOrganizationListForm.values.organizations.find(organization => organization.selected)
        if (selectedOrganization) {
            const res = await dispatch(sendOrganizationAdminInvite(
                {
                    organization_id: selectedOrganization.id,
                    admin_email: selectedOrganization.admin_email
                }
            ))

            if (sendOrganizationAdminInvite.fulfilled.match(res)) {
                window.toast("Invitation send successfully")
            }
            else if (sendOrganizationAdminInvite.rejected.match(res)) {
                window.toast("Error occured while resending invite.", 'error')
            }
        } else {
            window.toast("Please select an organization", 'error')
        }

    }

    return (
        <>
            <AddCompanyModal
                modalOpened={opened}
                modalClose={close}
            />
            <Flex
                direction="column"
                justify="flex-start"
                gap={24}
            >
                <Stack gap={24} mx={24} mt={24}>
                    <Stack gap={12}>
                        <Title fz={24} fw={700}>Manage and invite to new Company</Title>
                        <Text fz={16} fw={400} c="#5C5F66">You can invite upto 10 companies</Text>
                    </Stack>
                    <Group justify="space-between" align="center">
                        <TextInput
                            rightSection={clearSearchIcon}
                            leftSection={searchIcon}
                            placeholder="Search"
                            w={332}
                        />
                        <Group>
                            <Button key="resend_invite" size="sm" h={32} className="light-button" radius={6} onClick={resendInvitation}>Resend invite</Button>
                            <Button key="delete_company" size="sm"
                                className={isOrganizationSelected() ? 'danger-button' : 'light-button'}
                                radius={6}
                                onClick={deleteSelectedOrganization}
                                h={32}
                            >
                                <Group spacing="xs" align='flex-end'>
                                    <Text span> Delete company</Text>
                                    {deleteCompanyButtonLoading && <Loader size="sm" color="white" type='dots' />}
                                </Group>

                            </Button>
                            <Button key="add_company" h={32} size="sm" bg="#3354F4" c="#ffffff" radius={6} onClick={openAddCompanyModal}>Add company</Button>
                        </Group>
                    </Group>
                </Stack>
                <form onSubmit={updateOrganizationList} >
                    <Stack gap={24}>
                        <Divider />
                        <Box mx={0} style={{ overflowY: 'hidden', overflowX: width < 1420 ? 'auto' : 'hidden' }}>
                            {organizationListTemplate}
                        </Box>
                        <Divider />
                        <Button ml="auto" mr={24} mb={24} type='submit' bg="#3354F4" color="#ffffff" radius={6}>
                            <Group spacing="xs" align='flex-end'>
                                <Text span> Save Changes</Text>
                                {loading && <Loader size="sm" color="white" type='dots' />}
                            </Group>
                        </Button>
                    </Stack>
                </form>
            </Flex>
        </>
    )
}

export default Company